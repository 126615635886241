"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listGoods = listGoods;
exports.deleteGoods = deleteGoods;
exports.publishGoods = publishGoods;
exports.detailGoods = detailGoods;
exports.editGoods = editGoods;
exports.listCatAndBrand = listCatAndBrand;

var _request = _interopRequireDefault(require("@/utils/request"));

function listGoods(query) {
  return (0, _request.default)({
    url: '/goods/list',
    method: 'get',
    params: query
  });
}

function deleteGoods(data) {
  return (0, _request.default)({
    url: '/goods/delete',
    method: 'post',
    data: data
  });
}

function publishGoods(data) {
  return (0, _request.default)({
    url: '/goods/create',
    method: 'post',
    data: data
  });
}

function detailGoods(id) {
  return (0, _request.default)({
    url: '/goods/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}

function editGoods(data) {
  return (0, _request.default)({
    url: '/goods/update',
    method: 'post',
    data: data
  });
}

function listCatAndBrand() {
  return (0, _request.default)({
    url: '/goods/catAndBrand',
    method: 'get'
  });
}