"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = isExternal;
exports.validUsername = validUsername;
exports.validURL = validURL;
exports.validLowerCase = validLowerCase;
exports.validUpperCase = validUpperCase;
exports.validAlphabets = validAlphabets;
exports.validEmail = validEmail;
exports.isString = isString;
exports.isArray = isArray;
exports.isNumber = isNumber;
exports.isNumberNoFill = isNumberNoFill;
exports.isMobile = isMobile;
exports.isIcard = isIcard;
exports.isDatePicker = isDatePicker;

require("core-js/modules/es6.regexp.to-string");

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
/**
 * @param {string} url
 * @returns {Boolean}
 */


function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}
/**
 * @param {string} email
 * @returns {Boolean}
 */


function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }

  return false;
}
/**
 * @param {Array} arg
 * @returns {Boolean}
 */


function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }

  return Array.isArray(arg);
}
/*
* 必填的数字类型
* */


function isNumber(rule, value, callback) {
  var filed = rule.field;
  var reg = /\d+(\.\d{0,2})?/;
  console.log(filed, value, 11111);

  if (value && !reg.test(value)) {
    callback(new Error('请输入数值类型'));
  } else if (!value) {
    var msg = '';

    if (filed === 'counterPrice') {
      msg = '请输入商品标价';
    } else if (filed === '') {
      msg = '请输入';
    } else if (filed === '') {
      msg = '请输入';
    } else if (filed === '') {
      msg = '请输入';
    } else if (filed === '') {
      msg = '请输入';
    } else if (filed === '') {
      msg = '请输入';
    } else if (filed === '') {
      msg = '请输入';
    } else if (filed === '') {
      msg = '请输入';
    }

    callback(new Error(msg));
  } else {
    if (filed === 'sortNo' && (value > 100 || value < 1)) {
      callback(new Error('请输入>=1且<=100的数字'));
      return;
    }

    callback();
  }
}
/*
* 不必填的数字类型
* */


function isNumberNoFill(rule, value, callback) {
  var reg = /\d+(\.\d{0,2})?/;

  if (value && !reg.test(value)) {
    callback(new Error('请输入数值类型'));
  } else {
    callback();
  }
}
/*
* 手机号码验证
* */


function isMobile(rule, value, callback) {
  var reg = /^1[3|5|7|8][0-9]\d{4,8}$/;
  console.log(reg.test(value), '手机号======');

  if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
}
/*
* 验证身份证
*
* */


function isIcard(rule, value, callback) {
  var reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
  console.log(reg.test(value), 999999);

  if (!reg.test(value)) {
    callback(new Error('请输入正确的身份证号'));
  } else {
    callback();
  }
}
/*
* 日期验证
*
* */


function isDatePicker(rule, value, callback) {
  console.log(value, 111111);

  if (!value || value.length === 0) {
    callback(new Error('活动日期不能为空'));
  } else {
    callback();
  }
}