"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchList = fetchList;
exports.fetchListDetail = fetchListDetail;
exports.vipList = vipList;
exports.listEmployee = listEmployee;
exports.createEmployee = createEmployee;
exports.markEmployee = markEmployee;
exports.transferEmployee = transferEmployee;
exports.listAddress = listAddress;
exports.listCollect = listCollect;
exports.listFeedback = listFeedback;
exports.listFootprint = listFootprint;
exports.listHistory = listHistory;

var _request = _interopRequireDefault(require("@/utils/request"));

function fetchList(query) {
  return (0, _request.default)({
    url: '/user/list',
    method: 'get',
    params: query
  });
}

function fetchListDetail(query) {
  return (0, _request.default)({
    url: '/employee/read',
    method: 'post',
    params: query
  });
}

function vipList(query) {
  return (0, _request.default)({
    url: '/user/vip_list',
    method: 'get',
    params: query
  });
}

function listEmployee(query) {
  return (0, _request.default)({
    url: '/employee/list',
    method: 'get',
    params: query
  });
}

function createEmployee(data) {
  return (0, _request.default)({
    url: '/employee/create',
    method: 'post',
    data: data
  });
}

function markEmployee(data) {
  return (0, _request.default)({
    url: '/employee/mark',
    method: 'post',
    data: data
  });
}

function transferEmployee(data) {
  return (0, _request.default)({
    url: '/employee/transfer',
    method: 'post',
    data: data
  });
}

function listAddress(query) {
  return (0, _request.default)({
    url: '/address/list',
    method: 'get',
    params: query
  });
}

function listCollect(query) {
  return (0, _request.default)({
    url: '/collect/list',
    method: 'get',
    params: query
  });
}

function listFeedback(query) {
  return (0, _request.default)({
    url: '/feedback/list',
    method: 'get',
    params: query
  });
}

function listFootprint(query) {
  return (0, _request.default)({
    url: '/footprint/list',
    method: 'get',
    params: query
  });
}

function listHistory(query) {
  return (0, _request.default)({
    url: '/history/list',
    method: 'get',
    params: query
  });
}