"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/defineProperty"));

var _router = _interopRequireDefault(require("./router"));

var _store = _interopRequireDefault(require("./store"));

var _elementUi = require("element-ui");

var _nprogress = _interopRequireDefault(require("nprogress"));

require("nprogress/nprogress.css");

var _auth = require("@/utils/auth");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// getToken from cookie
_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration
// permission judge function


function hasPermission(perms, permissions) {
  if (perms.indexOf('*') >= 0) return true; // admin permission passed directly

  if (!permissions) return true;
  return perms.some(function (perm) {
    return permissions.indexOf(perm) >= 0;
  });
}

var whiteList = ['/login', '/auth-redirect']; // no redirect whitelist

_router.default.beforeEach(function (to, from, next) {
  _nprogress.default.start(); // start progress bar


  if ((0, _auth.getToken)()) {
    // determine if there has token

    /* has token*/
    if (to.path === '/login') {
      next({
        path: '/'
      });

      _nprogress.default.done(); // if current page is dashboard will not trigger	afterEach hook, so manually handle it

    } else {
      if (_store.default.getters.perms.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch('GetUserInfo').then(function (res) {
          // 拉取user_info
          var perms = res.data.d.perms; // note: perms must be a array! such as: ['GET /aaa','POST /bbb']

          _store.default.dispatch('GenerateRoutes', {
            perms: perms
          }).then(function () {
            // 根据perms权限生成可访问的路由表
            _router.default.addRoutes(_store.default.getters.addRouters); // 动态添加可访问路由表


            next(_objectSpread(_objectSpread({}, to), {}, {
              replace: true
            })); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
          });
        }).catch(function (err) {
          _store.default.dispatch('FedLogOut').then(function () {
            _elementUi.Message.error(err || 'Verification failed, please login again');

            next({
              path: '/'
            });
          });
        });
      } else {
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        if (hasPermission(_store.default.getters.perms, to.meta.perms)) {
          next();
        } else {
          next({
            path: '/401',
            replace: true,
            query: {
              noGoBack: true
            }
          });
        } // 可删 ↑

      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页

      _nprogress.default.done(); // if current page is login will not trigger afterEach hook, so manually handle it

    }
  }
});

_router.default.afterEach(function () {
  _nprogress.default.done(); // finish progress bar

});