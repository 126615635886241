"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listMall = listMall;
exports.updateMall = updateMall;
exports.listExpress = listExpress;
exports.updateExpress = updateExpress;
exports.listOrder = listOrder;
exports.updateOrder = updateOrder;
exports.listWx = listWx;
exports.updateWx = updateWx;

var _request = _interopRequireDefault(require("@/utils/request"));

function listMall() {
  return (0, _request.default)({
    url: '/config/mall',
    method: 'get'
  });
}

function updateMall(data) {
  return (0, _request.default)({
    url: '/config/mall',
    method: 'post',
    data: data
  });
}

function listExpress() {
  return (0, _request.default)({
    url: '/config/express',
    method: 'get'
  });
}

function updateExpress(data) {
  return (0, _request.default)({
    url: '/config/express',
    method: 'post',
    data: data
  });
}

function listOrder() {
  return (0, _request.default)({
    url: '/config/order',
    method: 'get'
  });
}

function updateOrder(data) {
  return (0, _request.default)({
    url: '/config/order',
    method: 'post',
    data: data
  });
}

function listWx() {
  return (0, _request.default)({
    url: '/config/wx',
    method: 'get'
  });
}

function updateWx(data) {
  return (0, _request.default)({
    url: '/config/wx',
    method: 'post',
    data: data
  });
}