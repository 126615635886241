"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.split");

var _goods = require("@/api/goods");

var _storage = require("@/api/storage");

var _elementUi = require("element-ui");

var _auth = require("@/utils/auth");

var _validate = require("../../utils/validate");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'GoodsEdit',
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      newKeywordVisible: false,
      newKeyword: '',
      keywords: [],
      galleryFileList: [],
      detailList: [],
      testReportsList: [],
      categoryList: [],
      brandList: [],
      categoryIds: [],
      goods: {
        gallery: [],
        detail: [],
        testReports: [] // goodsType: 0

      },
      keywardVisiable: false,
      specVisiable: false,
      keyIndicatorsVisiable: false,
      specForm: {
        specification: '',
        value: '',
        picUrl: '',
        counterPrice: 0,
        retailPrice: 0,
        vipPrice: 0
      },
      specifications: [{
        specification: '规格',
        value: '标准',
        picUrl: ''
      }],
      productVisiable: false,
      productForm: {
        id: '',
        specifications: [],
        productSn: '',
        price: 0.00,
        number: 0,
        vipPrice: 0.00
      },
      products: [{
        id: 0,
        specifications: ['标准'],
        price: 0.0,
        number: 0,
        url: ''
      }],
      attributeVisiable: false,
      attributeAdd: true,
      attributeForm: {
        attribute: '',
        value: ''
      },
      attributes: [],
      rules: {
        name: [{
          required: true,
          message: '商品名称不能为空',
          trigger: 'blur'
        }],
        counterPrice: [{
          trigger: 'blur',
          validator: _validate.isNumber
        }]
      },
      productsRules: {
        productSn: [{
          required: true,
          message: '货品品编号不能为空',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          trigger: 'blur',
          validator: _validate.isNumber
        }],
        vipPrice: [{
          required: true,
          trigger: 'blur',
          validator: _validate.isNumber
        }]
      },
      keyIndicators: [],
      keyIndicatorsForm: {
        name: '',
        unit: '',
        value: '',
        limited: ''
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        'x-auth-token': (0, _auth.getToken)()
      };
    },
    attributesData: function attributesData() {
      var attributesData = [];

      for (var i = 0; i < this.attributes.length; i++) {
        if (this.attributes[i].deleted) {
          continue;
        }

        attributesData.push(this.attributes[i]);
      }

      return attributesData;
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      if (this.$route.query.id == null) {
        return;
      }

      var goodsId = this.$route.query.id;
      (0, _goods.detailGoods)(goodsId).then(function (response) {
        var data = response.data.d;

        if (data && data !== null) {
          _this.goods = data.goods && data.goods !== null ? data.goods : _this.goods;

          if (!_this.goods.detail || _this.goods.detail == null) {
            _this.goods.detail = [];
          }

          if (!_this.goods.testReports || _this.goods.testReports == null) {
            _this.goods.testReports = [];
          }

          if (!_this.goods.gallery || _this.goods.gallery == null) {
            _this.goods.gallery = [];
          } // 稍微调整一下前后端不一致


          if (_this.goods.brandId === 0) {
            _this.goods.brandId = null;
          }

          if (_this.goods.keywords === '') {
            _this.goods.keywords = null;
          }

          _this.specifications = data.specifications && data.specifications !== null && data.specifications.length > 0 ? data.specifications : _this.specifications;
          _this.products = data.products && data.products !== null ? data.products : _this.products;
          _this.attributes = data.attributes && data.attributes !== null ? data.attributes : _this.attributes;
          var categoryIds = data.categoryIds && data.categoryIds !== null && data.categoryIds.length > 0 ? data.categoryIds : [];

          if (categoryIds.length > 0) {
            if (categoryIds[0] === 0) {
              _this.categoryIds = categoryIds.splice(1);
            } else {
              _this.categoryIds = categoryIds;
            }
          }

          _this.keyIndicators = data.keyIndicators && data.keyIndicators !== null ? data.keyIndicators : _this.keyIndicators;
          _this.galleryFileList = [];
          _this.detailList = [];
          _this.testReportsList = [];

          if (_this.goods.gallery && _this.goods.gallery !== null) {
            for (var i = 0; i < _this.goods.gallery.length; i++) {
              _this.galleryFileList.push({
                url: _this.goods.gallery[i]
              });
            }
          }

          if (_this.goods.detail && _this.goods.detail !== null) {
            for (var _i = 0; _i < _this.goods.detail.length; _i++) {
              _this.detailList.push({
                url: _this.goods.detail[_i].url
              });
            }
          }

          if (_this.goods.testReports && _this.goods.testReports !== null) {
            for (var _i2 = 0; _i2 < _this.goods.testReports.length; _i2++) {
              _this.testReportsList.push({
                url: _this.goods.detail[_i2].url
              });
            }
          }

          if (_this.goods.keywords && _this.goods.keywords !== null) {
            _this.keywords = _this.goods.keywords.split(',');
          }
        }
      });
      (0, _goods.listCatAndBrand)().then(function (response) {
        _this.categoryList = response.data.d.categoryList;
        _this.brandList = response.data.d.brandList;
      });
    },
    handleCategoryChange: function handleCategoryChange(value) {
      console.log(value);
      this.goods.categoryId = value[value.length - 1];
    },
    handleCancel: function handleCancel() {
      this.$router.push({
        path: '/goods/list'
      });
    },
    handleEdit: function handleEdit() {
      var _this2 = this;

      this.$refs.goods.validate(function (valid) {
        if (valid) {
          var finalGoods = {
            goods: _this2.goods,
            specifications: _this2.specifications,
            products: _this2.products,
            attributes: _this2.attributes,
            keyIndicators: _this2.keyIndicators,
            testReports: _this2.testReports
          };
          (0, _goods.editGoods)(finalGoods).then(function (response) {
            _this2.$notify.success({
              title: '成功',
              message: '创建成功'
            });

            _this2.$router.push({
              path: '/goods/list'
            });
          }).catch(function (response) {
            _elementUi.MessageBox.alert('业务错误：' + response.data.m, '警告', {
              confirmButtonText: '确定',
              type: 'error'
            });
          });
        } else {
          _this2.$message('请填写必填选项');
        }
      });
    },
    handleClose: function handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1);
      this.goods.keywords = this.keywords.toString();
    },
    showInput: function showInput() {
      var _this3 = this;

      // 原操作
      this.newKeywordVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.newKeywordInput.$refs.input.focus();
      }); // this.keywardVisiable = true
    },
    handleInputConfirm: function handleInputConfirm() {
      var newKeyword = this.newKeyword;

      if (newKeyword) {
        this.keywords.push(newKeyword);
        this.goods.keywords = this.keywords.toString();
      }

      this.newKeywordVisible = false;
      this.newKeyword = '';
    },
    uploadPicUrl: function uploadPicUrl(response) {
      this.goods.picUrl = response.d.url;
    },
    uploadOverrun: function uploadOverrun() {
      this.$message({
        type: 'error',
        message: '上传文件个数超出限制!最多上传5张图片!'
      });
    },
    handleGalleryUrl: function handleGalleryUrl(response, file, fileList, index) {
      var that = this;

      if (response.c === 0) {
        var url = response.d.url;

        if (index === 1) {
          that.goods.gallery.push(url);
        } else if (index === 2) {
          var img = new Image();
          img.src = url;

          img.onload = function () {
            var width = img.width;
            var height = img.height;
            var obj = {
              url: url,
              size: "".concat(width, "*").concat(height)
            };
            console.log(that.goods, 33333);
            that.goods.detail.push(obj);
          };
        } else {
          var _img = new Image();

          _img.src = url;

          _img.onload = function () {
            var width = _img.width;
            var height = _img.height;
            var obj = {
              url: url,
              size: "".concat(width, "*").concat(height)
            };
            console.log(that.goods, 44444);
            that.goods.testReports.push(obj);
          };
        }
      }
    },
    handleRemove: function handleRemove(file, fileList, index) {
      console.log(index, 9999999);

      if (index === 1) {
        for (var i = 0; i < this.goods.gallery.length; i++) {
          // 这里存在两种情况
          // 1. 如果所删除图片是刚刚上传的图片，那么图片地址是file.response.d.url
          //    此时的file.url虽然存在，但是是本机地址，而不是远程地址。
          // 2. 如果所删除图片是后台返回的已有图片，那么图片地址是file.url
          var url = void 0;

          if (file.response === undefined) {
            url = file.url;
          } else {
            url = file.response.d.url;
          }

          if (this.goods.gallery[i] === url) {
            this.goods.gallery.splice(i, 1);
          }
        }
      } else if (index === 2) {
        for (var _i3 = 0; _i3 < this.goods.detail.length; _i3++) {
          var _url = void 0;

          if (file.response === undefined) {
            _url = file.url;
          } else {
            _url = file.response.d.url;
          }

          if (this.goods.detail[_i3].url === _url) {
            this.goods.detail.splice(_i3, 1);
          }

          console.log(this.goods.detail, 444444);
        }
      } else {
        for (var _i4 = 0; _i4 < this.goods.testReports.length; _i4++) {
          var _url2 = void 0;

          if (file.response === undefined) {
            _url2 = file.url;
          } else {
            _url2 = file.response.d.url;
          }

          if (this.goods.testReports[_i4].url === _url2) {
            this.goods.testReports.splice(_i4, 1);
          }
        }
      }
    },
    specChanged: function specChanged(label) {
      if (label === false) {
        this.specifications = [{
          specification: '规格',
          value: '标准',
          picUrl: ''
        }];
        this.products = [{
          id: 0,
          specifications: ['标准'],
          price: 0.0,
          number: 0,
          url: ''
        }];
      } else {
        this.specifications = [];
        this.products = [];
      }
    },
    uploadSpecPicUrl: function uploadSpecPicUrl(response) {
      this.specForm.picUrl = response.d.url;
    },
    handleSpecificationShow: function handleSpecificationShow(row) {
      this.specForm = Object.assign({}, row);
      this.specVisiable = true;
    },
    handleSpecificationEdit: function handleSpecificationEdit() {
      this.specForm.updateTime = '';

      for (var i = 0; i < this.specifications.length; i++) {
        var v = this.specifications[i];

        if (v.id === this.specForm.id) {
          this.specifications.splice(i, 1, this.specForm);
          break;
        }
      }

      this.specVisiable = false;
    },
    handleProductShow: function handleProductShow(row) {
      this.productForm = Object.assign({}, row);
      this.productVisiable = true;
    },
    uploadProductUrl: function uploadProductUrl(response) {
      this.productForm.url = response.d.url;
    },
    handleProductEdit: function handleProductEdit() {
      this.productForm.updateTime = '';

      for (var i = 0; i < this.products.length; i++) {
        var v = this.products[i];

        if (v.id === this.productForm.id) {
          this.products.splice(i, 1, this.productForm);
          break;
        }
      }

      this.productVisiable = false;
    },
    handleAttributeShow: function handleAttributeShow(row) {
      if (row.id) {
        this.attributeForm = Object.assign({}, row);
        this.attributeAdd = false;
      } else {
        this.attributeForm = {};
        this.attributeAdd = true;
      }

      this.attributeVisiable = true;
    },
    handleAttributeAdd: function handleAttributeAdd() {
      this.attributes.unshift(this.attributeForm);
      this.attributeVisiable = false;
    },
    handleAttributeEdit: function handleAttributeEdit() {
      // 这是一个trick，设置updateTime的值为空，告诉后端这个记录已编辑需要更新。
      this.attributeForm.updateTime = '';

      for (var i = 0; i < this.attributes.length; i++) {
        var v = this.attributes[i];

        if (v.id === this.attributeForm.id) {
          this.attributes.splice(i, 1, this.attributeForm);
          break;
        }
      }

      this.attributeVisiable = false;
    },
    handleAttributeDelete: function handleAttributeDelete(row) {
      row.deleted = true;
    },
    handleKeyIndicatorsShow: function handleKeyIndicatorsShow() {
      // this.keyIndicators = []
      this.keyIndicatorsVisiable = true;
    },
    handleKeyIndicatorsAdd: function handleKeyIndicatorsAdd() {
      this.keyIndicators.unshift(this.keyIndicatorsForm);
      this.keyIndicatorsVisiable = false;
    },
    handleKeyIndicatorsDelete: function handleKeyIndicatorsDelete(row) {
      var index = this.keyIndicators.indexOf(row);
      this.keyIndicators.splice(index, 1);
    }
  }
};
exports.default = _default;