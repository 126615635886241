"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.to-string");

var _role = require("@/api/role");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default = {
  name: 'Role',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        name: undefined,
        desc: undefined
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{
          required: true,
          message: '角色名称不能为空',
          trigger: 'blur'
        }]
      },
      permissionDialogFormVisible: false,
      systemPermissions: null,
      assignedPermissions: null,
      permissionForm: {
        roleId: undefined,
        permissions: []
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _role.listRole)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this.list = response.data.d.list;

          if (response.data.d.totalPage) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: undefined,
        desc: undefined
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _role.createRole)(_this3.dataForm).then(function (response) {
            _this3.list.unshift(response.data.d);

            _this3.dialogFormVisible = false;

            _this3.$notify.success({
              title: '成功',
              message: '添加角色成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.dataForm = Object.assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _role.updateRole)(_this5.dataForm).then(function () {
            var _iterator = _createForOfIteratorHelper(_this5.list),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;

                if (v.id === _this5.dataForm.id) {
                  var index = _this5.list.indexOf(v);

                  _this5.list.splice(index, 1, _this5.dataForm);

                  break;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }

            _this5.dialogFormVisible = false;

            _this5.$notify.success({
              title: '成功',
              message: '更新管理员成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      (0, _role.deleteRole)(row).then(function (response) {
        _this6.$notify.success({
          title: '成功',
          message: '删除管理员成功'
        });

        var index = _this6.list.indexOf(row);

        _this6.list.splice(index, 1);
      }).catch(function (response) {
        _this6.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handlePermission: function handlePermission(row) {
      var _this7 = this;

      this.permissionDialogFormVisible = true;
      this.permissionForm.roleId = row.id;
      (0, _role.getPermission)({
        roleId: row.id
      }).then(function (response) {
        _this7.systemPermissions = response.data.d.systemPermissions;
        _this7.assignedPermissions = response.data.d.assignedPermissions;
      });
    },
    updatePermission: function updatePermission() {
      var _this8 = this;

      this.permissionForm.permissions = this.$refs.tree.getCheckedKeys(true);
      (0, _role.updatePermission)(this.permissionForm).then(function (response) {
        _this8.permissionDialogFormVisible = false;

        _this8.$notify.success({
          title: '成功',
          message: '授权成功'
        });
      }).catch(function (response) {
        _this8.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    }
  }
};
exports.default = _default;