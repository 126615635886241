var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品介绍")]),
          _c(
            "el-form",
            {
              ref: "goods",
              attrs: {
                rules: _vm.rules,
                model: _vm.goods,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品ID", prop: "id" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.goods.id,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "id", $$v)
                      },
                      expression: "goods.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.goods.name,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "name", $$v)
                      },
                      expression: "goods.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品简介" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.goods.brief,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "brief", $$v)
                      },
                      expression: "goods.brief"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品编号", prop: "goodsSn" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.goods.goodsSn,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "goodsSn", $$v)
                      },
                      expression: "goods.goodsSn"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标价", prop: "counterPrice" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "0.00" },
                      model: {
                        value: _vm.goods.counterPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "counterPrice", $$v)
                        },
                        expression: "goods.counterPrice"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售价", prop: "retailPrice" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "0.00" },
                      model: {
                        value: _vm.goods.retailPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "retailPrice", $$v)
                        },
                        expression: "goods.retailPrice"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "会员价", prop: "vipPrice" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "0.00" },
                      model: {
                        value: _vm.goods.vipPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "vipPrice", $$v)
                        },
                        expression: "goods.vipPrice"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否新品", prop: "isNew" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.isNew,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "isNew", $$v)
                        },
                        expression: "goods.isNew"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("新品")
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("非新品")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否热卖", prop: "isHot" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.isHot,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "isHot", $$v)
                        },
                        expression: "goods.isHot"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("普通")
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("热卖")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否在售", prop: "isOnSale" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.goods.isOnSale,
                        callback: function($$v) {
                          _vm.$set(_vm.goods, "isOnSale", $$v)
                        },
                        expression: "goods.isOnSale"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("在售")
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("未售")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品图片" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        headers: _vm.headers,
                        action: _vm.uploadPath,
                        "show-file-list": false,
                        "on-success": _vm.uploadPicUrl,
                        accept: ".jpg,.jpeg,.png,.gif"
                      }
                    },
                    [
                      _vm.goods.picUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.goods.picUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "宣传画廊" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadPath,
                        headers: _vm.headers,
                        limit: 5,
                        "file-list": _vm.galleryFileList,
                        "on-exceed": _vm.uploadOverrun,
                        "on-success": function(res, file, fileList) {
                          return _vm.handleGalleryUrl(res, file, fileList, 1)
                        },
                        "on-remove": function(file, fileList) {
                          return _vm.handleRemove(file, fileList, 1)
                        },
                        multiple: "",
                        accept: ".jpg,.jpeg,.png,.gif",
                        "list-type": "picture-card"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品单位" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "件 / 个 / 盒" },
                    model: {
                      value: _vm.goods.unit,
                      callback: function($$v) {
                        _vm.$set(_vm.goods, "unit", $$v)
                      },
                      expression: "goods.unit"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关键字" } },
                [
                  _vm._l(_vm.keywords, function(tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        attrs: { closable: "", type: "primary" },
                        on: {
                          close: function($event) {
                            return _vm.handleClose(tag)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(tag) + " ")]
                    )
                  }),
                  _vm.newKeywordVisible
                    ? _c("el-input", {
                        ref: "newKeywordInput",
                        staticClass: "input-new-keyword",
                        on: { blur: _vm.handleInputConfirm },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleInputConfirm($event)
                          }
                        },
                        model: {
                          value: _vm.newKeyword,
                          callback: function($$v) {
                            _vm.newKeyword = $$v
                          },
                          expression: "newKeyword"
                        }
                      })
                    : _c(
                        "el-button",
                        {
                          staticClass: "button-new-keyword",
                          attrs: { type: "primary" },
                          on: { click: _vm.showInput }
                        },
                        [_vm._v("+ 增加")]
                      )
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属分类" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.categoryList,
                      props: { checkStrictly: true },
                      clearable: "",
                      "expand-trigger": "hover"
                    },
                    on: { change: _vm.handleCategoryChange },
                    model: {
                      value: _vm.categoryIds,
                      callback: function($$v) {
                        _vm.categoryIds = $$v
                      },
                      expression: "categoryIds"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品详细介绍" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.uploadPath,
                        headers: _vm.headers,
                        "file-list": _vm.detailList,
                        "on-exceed": _vm.uploadOverrun,
                        "on-success": function(res, file, fileList) {
                          return _vm.handleGalleryUrl(res, file, fileList, 2)
                        },
                        "on-remove": function(file, fileList) {
                          return _vm.handleRemove(file, fileList, 2)
                        },
                        multiple: "",
                        accept: ".jpg,.jpeg,.png,.gif",
                        "list-type": "picture-card"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品规格")]),
          _c(
            "el-table",
            { attrs: { data: _vm.specifications } },
            [
              _c("el-table-column", {
                attrs: { property: "specification", label: "规格名" }
              }),
              _c("el-table-column", {
                attrs: { property: "value", label: "规格值" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v(" " + _vm._s(scope.row.value) + " ")
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "picUrl", label: "规格图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.picUrl
                          ? _c("img", {
                              attrs: { src: scope.row.picUrl, width: "40" }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "200",
                  "class-name": "small-padding fixed-width"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleSpecificationShow(scope.row)
                              }
                            }
                          },
                          [_vm._v("设置")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.specVisiable, title: "设置规格" },
              on: {
                "update:visible": function($event) {
                  _vm.specVisiable = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "specForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.specForm,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格名", prop: "specification" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.specForm.specification,
                          callback: function($$v) {
                            _vm.$set(_vm.specForm, "specification", $$v)
                          },
                          expression: "specForm.specification"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格值", prop: "value" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.specForm.value,
                          callback: function($$v) {
                            _vm.$set(_vm.specForm, "value", $$v)
                          },
                          expression: "specForm.value"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标价", prop: "counterPrices" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.specForm.counterPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.specForm, "counterPrice", $$v)
                          },
                          expression: "specForm.counterPrice"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "售价", prop: "retailPrice" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.specForm.retailPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.specForm, "retailPrice", $$v)
                          },
                          expression: "specForm.retailPrice"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员价", prop: "vipPrice" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.specForm.vipPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.specForm, "vipPrice", $$v)
                          },
                          expression: "specForm.vipPrice"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格图片", prop: "picUrl" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.uploadPath,
                            "show-file-list": false,
                            headers: _vm.headers,
                            "on-success": _vm.uploadSpecPicUrl,
                            accept: ".jpg,.jpeg,.png,.gif"
                          }
                        },
                        [
                          _vm.specForm.picUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.specForm.picUrl }
                              })
                            : _c("i", {
                                staticClass: "el-icon-plus avatar-uploader-icon"
                              })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.specVisiable = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSpecificationEdit }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("商品库存")]),
          _c(
            "el-table",
            { attrs: { data: _vm.products } },
            [
              _c("el-table-column", {
                attrs: { property: "value", label: "货品规格" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return _vm._l(scope.row.specifications, function(tag) {
                        return _c("el-tag", { key: tag }, [
                          _vm._v(" " + _vm._s(tag) + " ")
                        ])
                      })
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { property: "productSn", label: "货品编码" }
              }),
              _c("el-table-column", {
                attrs: { property: "number", label: "库存数量" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "200",
                  "class-name": "small-padding fixed-width"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleProductShow(scope.row)
                              }
                            }
                          },
                          [_vm._v("设置")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.productVisiable, title: "编辑货品" },
              on: {
                "update:visible": function($event) {
                  _vm.productVisiable = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "productForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.productForm,
                    rules: _vm.productsRules,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品编码", prop: "productSn" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入货品编码" },
                        model: {
                          value: _vm.productForm.productSn,
                          callback: function($$v) {
                            _vm.$set(_vm.productForm, "productSn", $$v)
                          },
                          expression: "productForm.productSn"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "货品售价", prop: "price" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.productForm.price,
                          callback: function($$v) {
                            _vm.$set(_vm.productForm, "price", $$v)
                          },
                          expression: "productForm.price"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "会员价", prop: "vipPrice" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.productForm.vipPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.productForm, "vipPrice", $$v)
                          },
                          expression: "productForm.vipPrice"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "库存数量", prop: "number" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入库存数量" },
                        model: {
                          value: _vm.productForm.number,
                          callback: function($$v) {
                            _vm.$set(_vm.productForm, "number", $$v)
                          },
                          expression: "productForm.number"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.productVisiable = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleProductEdit }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "title-add-btn-box" },
            [
              _c("h3", [_vm._v("商品参数")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleAttributeShow }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.attributesData } },
            [
              _c("el-table-column", {
                attrs: { property: "attribute", label: "商品参数名称" }
              }),
              _c("el-table-column", {
                attrs: { property: "value", label: "商品参数值" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  width: "200",
                  "class-name": "small-padding fixed-width"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleAttributeShow(scope.row)
                              }
                            }
                          },
                          [_vm._v("设置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleAttributeDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.attributeVisiable,
                title: _vm.attributeAdd ? "添加商品参数" : "编辑商品参数"
              },
              on: {
                "update:visible": function($event) {
                  _vm.attributeVisiable = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "attributeForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.attributeForm,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品参数名称", prop: "attribute" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.attributeForm.attribute,
                          callback: function($$v) {
                            _vm.$set(_vm.attributeForm, "attribute", $$v)
                          },
                          expression: "attributeForm.attribute"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品参数值", prop: "value" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.attributeForm.value,
                          callback: function($$v) {
                            _vm.$set(_vm.attributeForm, "value", $$v)
                          },
                          expression: "attributeForm.value"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.attributeVisiable = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.attributeAdd
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleAttributeAdd }
                        },
                        [_vm._v("确定")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleAttributeEdit }
                        },
                        [_vm._v("确定")]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "title-add-btn-box" },
            [
              _c("h3", [_vm._v("关键指标")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleKeyIndicatorsShow }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.keyIndicators } },
            [
              _c("el-table-column", {
                attrs: { property: "name", label: "项目" }
              }),
              _c("el-table-column", {
                attrs: { property: "unit", label: "单位" }
              }),
              _c("el-table-column", {
                attrs: { property: "value", label: "检测结果" }
              }),
              _c("el-table-column", {
                attrs: { property: "limited", label: "定量限" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "操作",
                  "class-name": "small-padding fixed-width"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleKeyIndicatorsShow(scope.row)
                              }
                            }
                          },
                          [_vm._v("设置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleKeyIndicatorsDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.keyIndicatorsVisiable,
                title: "添加关键指标"
              },
              on: {
                "update:visible": function($event) {
                  _vm.keyIndicatorsVisiable = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "attributeForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.keyIndicatorsForm,
                    "status-icon": "",
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入项目名称" },
                        model: {
                          value: _vm.keyIndicatorsForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.keyIndicatorsForm, "name", $$v)
                          },
                          expression: "keyIndicatorsForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位", prop: "unit" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入单位" },
                        model: {
                          value: _vm.keyIndicatorsForm.unit,
                          callback: function($$v) {
                            _vm.$set(_vm.keyIndicatorsForm, "unit", $$v)
                          },
                          expression: "keyIndicatorsForm.unit"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "检测结果", prop: "value" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入检测结果" },
                        model: {
                          value: _vm.keyIndicatorsForm.value,
                          callback: function($$v) {
                            _vm.$set(_vm.keyIndicatorsForm, "value", $$v)
                          },
                          expression: "keyIndicatorsForm.value"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "定量限", prop: "limited" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入定量限" },
                        model: {
                          value: _vm.keyIndicatorsForm.limited,
                          callback: function($$v) {
                            _vm.$set(_vm.keyIndicatorsForm, "limited", $$v)
                          },
                          expression: "keyIndicatorsForm.limited"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.keyIndicatorsVisiable = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleKeyIndicatorsAdd }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v("鉴定详情")]),
          _c(
            "el-upload",
            {
              attrs: {
                action: _vm.uploadPath,
                headers: _vm.headers,
                "file-list": _vm.testReportsList,
                "on-exceed": _vm.uploadOverrun,
                "on-success": function(res, file, fileList) {
                  return _vm.handleGalleryUrl(res, file, fileList, 3)
                },
                "on-remove": function(file, fileList) {
                  return _vm.handleRemove(file, fileList, 3)
                },
                multiple: "",
                accept: ".jpg,.jpeg,.png,.gif",
                "list-type": "picture-card"
              }
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "op-container" },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消")
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleEdit } },
            [_vm._v("更新商品")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }