"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

var _goods = require("@/api/goods");

var _storage = require("@/api/storage");

var _elementUi = require("element-ui");

var _auth = require("@/utils/auth");

var _validate = require("../../utils/validate");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'GoodsCreate',
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      newKeywordVisible: false,
      newKeyword: '',
      keywords: [],
      categoryList: [],
      brandList: [],
      goods: {
        picUrl: '',
        gallery: [],
        isHot: false,
        isNew: true,
        isOnSale: true,
        detail: [],
        vipPrice: 0.00,
        testReports: [],
        goodsType: 0
      },
      specVisiable: false,
      specForm: {
        specification: '',
        value: '',
        picUrl: '',
        counterPrice: 0,
        retailPrice: 0,
        vipPrice: 0
      },
      multipleSpec: false,
      specifications: [{
        specification: '规格',
        value: '标准',
        picUrl: ''
      }],
      productVisiable: false,
      productForm: {
        id: '',
        specifications: [],
        productSn: '',
        price: 0.00,
        number: 0,
        vipPrice: 0.00
      },
      products: [{
        id: 0,
        specifications: ['标准'],
        price: 0.00,
        number: 0,
        vipPrice: 0.00,
        url: ''
      }],
      attributeVisiable: false,
      keyIndicatorsVisiable: false,
      attributeForm: {
        attribute: '',
        value: ''
      },
      attributes: [],
      keyIndicators: [],
      keyIndicatorsForm: {
        name: '',
        unit: '',
        value: '',
        limited: ''
      },
      rules: {
        goodsSn: [{
          required: true,
          message: '商品编号不能为空',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '商品名称不能为空',
          trigger: 'blur'
        }],
        counterPrice: [{
          trigger: 'blur',
          validator: _validate.isNumber
        }],
        retailPrice: [{
          trigger: 'blur',
          validator: _validate.isNumberNoFill
        }],
        vipPrice: [{
          trigger: 'blur',
          validator: _validate.isNumberNoFill
        }]
      },
      productsRules: {
        productSn: [{
          required: true,
          message: '货品品编号不能为空',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          trigger: 'blur',
          validator: _validate.isNumber
        }],
        vipPrice: [{
          required: true,
          trigger: 'blur',
          validator: _validate.isNumber
        }]
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        'x-auth-token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _goods.listCatAndBrand)().then(function (response) {
        _this.categoryList = response.data.d.categoryList;
        _this.brandList = response.data.d.brandList;
      });
    },
    handleCategoryChange: function handleCategoryChange(value) {
      console.log(value, 11111);
      this.goods.categoryId = value[value.length - 1];
    },
    handleCancel: function handleCancel() {
      this.$router.push({
        path: '/goods/list'
      });
    },
    handlePublish: function handlePublish() {
      var _this2 = this;

      this.$refs.goods.validate(function (valid) {
        if (valid) {
          var finalGoods = {
            goods: _this2.goods,
            specifications: _this2.specifications,
            products: _this2.products,
            attributes: _this2.attributes,
            keyIndicators: _this2.keyIndicators,
            testReports: _this2.testReports
          };
          localStorage.setItem('test', JSON.stringify(finalGoods));
          (0, _goods.publishGoods)(finalGoods).then(function (response) {
            _this2.$notify.success({
              title: '成功',
              message: '创建成功'
            });

            _this2.$router.push({
              path: '/goods/list'
            });
          }).catch(function (response) {
            _elementUi.MessageBox.alert('业务错误：' + response.data.m, '警告', {
              confirmButtonText: '确定',
              type: 'error'
            });
          });
        }
      });
    },
    handleClose: function handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1);
      this.goods.keywords = this.keywords.toString();
    },
    showInput: function showInput() {
      var _this3 = this;

      this.newKeywordVisible = true;
      this.$nextTick(function (_) {
        _this3.$refs.newKeywordInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var newKeyword = this.newKeyword;

      if (newKeyword) {
        this.keywords.push(newKeyword);
        this.goods.keywords = this.keywords.toString();
      }

      this.newKeywordVisible = false;
      this.newKeyword = '';
    },
    uploadPicUrl: function uploadPicUrl(response) {
      console.log(response, 10000000);
      this.goods.picUrl = response.d.url;
    },
    uploadOverrun: function uploadOverrun() {
      this.$message({
        type: 'error',
        message: '上传文件个数超出限制!最多上传5张图片!'
      });
    },
    handleGalleryUrl: function handleGalleryUrl(response, file, fileList, index) {
      var that = this;

      if (response.c === 0) {
        var url = response.d.url;

        if (index === 1) {
          that.goods.gallery.push(url);
        } else if (index === 2) {
          var img = new Image();
          img.src = url;

          img.onload = function () {
            var width = img.width;
            var height = img.height;
            var obj = {
              url: url,
              size: "".concat(width, "*").concat(height)
            };
            that.goods.detail.push(obj);
            console.log(that.goods.detail, 11111);
          };
        } else {
          var _img = new Image();

          _img.src = url;

          _img.onload = function () {
            var width = _img.width;
            var height = _img.height;
            var obj = {
              url: url,
              size: "".concat(width, "*").concat(height)
            };
            that.goods.testReports.push(obj);
            console.log(that.goods.testReports, 11111);
          };
        }
      }
    },
    handleRemove: function handleRemove(file, fileList, index) {
      console.log(index, 111111111);

      if (index === 1) {
        for (var i = 0; i < this.goods.gallery.length; i++) {
          // 这里存在两种情况
          // 1. 如果所删除图片是刚刚上传的图片，那么图片地址是file.response.d.url
          //    此时的file.url虽然存在，但是是本机地址，而不是远程地址。
          // 2. 如果所删除图片是后台返回的已有图片，那么图片地址是file.url
          var url = void 0;

          if (file.response === undefined) {
            url = file.url;
          } else {
            url = file.response.d.url;
          }

          if (this.goods.gallery[i] === url) {
            this.goods.gallery.splice(i, 1);
          }
        }
      } else if (index === 2) {
        for (var _i = 0; _i < this.goods.detail.length; _i++) {
          var _url = void 0;

          if (file.response === undefined) {
            _url = file.url;
          } else {
            _url = file.response.d.url;
          }

          if (this.goods.detail[_i].url === _url) {
            this.goods.detail.splice(_i, 1);
          }
        }
      } else {
        for (var _i2 = 0; _i2 < this.goods.testReports.length; _i2++) {
          var _url2 = void 0;

          if (file.response === undefined) {
            _url2 = file.url;
          } else {
            _url2 = file.response.d.url;
          }

          if (this.goods.testReports[_i2].url === _url2) {
            this.goods.testReports.splice(_i2, 1);
          }
        }
      }
    },
    specChanged: function specChanged(label) {
      if (label === false) {
        this.specifications = [{
          specification: '规格',
          value: '标准',
          picUrl: ''
        }];
        this.products = [{
          id: 0,
          specifications: ['标准'],
          price: 0.00,
          number: 0,
          url: ''
        }];
      } else {
        this.specifications = [];
        this.products = [];
      }
    },
    uploadSpecPicUrl: function uploadSpecPicUrl(response) {
      console.log(response, 20000);
      this.specForm.picUrl = response.d.url;
    },
    handleSpecificationShow: function handleSpecificationShow() {
      this.specForm = {
        specification: '',
        value: '',
        picUrl: '',
        counterPrice: 0,
        retailPrice: 0,
        vipPrice: 0
      };
      this.specVisiable = true;
    },
    handleSpecificationAdd: function handleSpecificationAdd() {
      var index = this.specifications.length - 1;

      for (var i = 0; i < this.specifications.length; i++) {
        var v = this.specifications[i];

        if (v.specification === this.specForm.specification) {
          if (v.value === this.specForm.value) {
            this.$message({
              type: 'warning',
              message: '已经存在规格值:' + v.value
            });
            this.specForm = {};
            this.specVisiable = false;
            return;
          } else {
            index = i;
          }
        }
      }

      this.specifications.splice(index + 1, 0, this.specForm);
      this.specVisiable = false;
      this.specToProduct();
    },
    handleSpecificationDelete: function handleSpecificationDelete(row) {
      var index = this.specifications.indexOf(row);
      this.specifications.splice(index, 1);
      this.specToProduct();
    },
    specToProduct: function specToProduct() {
      if (this.specifications.length === 0) {
        return;
      } // 根据specifications创建临时规格列表


      var specValues = [];
      var spec = this.specifications[0].specification;
      var values = [];
      values.push(0);

      for (var i = 1; i < this.specifications.length; i++) {
        var aspec = this.specifications[i].specification;

        if (aspec === spec) {
          values.push(i);
        } else {
          specValues.push(values);
          spec = aspec;
          values = [];
          values.push(i);
        }
      }

      specValues.push(values); // 根据临时规格列表生产货品规格
      // 算法基于 https://blog.csdn.net/tyhj_sf/article/details/53893125

      var productsIndex = 0;
      var products = [];
      var combination = [];
      var n = specValues.length;

      for (var s = 0; s < n; s++) {
        combination[s] = 0;
      }

      var index = 0;
      var isContinue = false;

      do {
        var specifications = [];

        for (var x = 0; x < n; x++) {
          var z = specValues[x][combination[x]];
          specifications.push(this.specifications[z].value);
        }

        products[productsIndex] = {
          id: productsIndex,
          specifications: specifications,
          price: 0.00,
          number: 0,
          url: ''
        };
        productsIndex++;
        index++;
        combination[n - 1] = index;

        for (var j = n - 1; j >= 0; j--) {
          if (combination[j] >= specValues[j].length) {
            combination[j] = 0;
            index = 0;

            if (j - 1 >= 0) {
              combination[j - 1] = combination[j - 1] + 1;
            }
          }
        }

        isContinue = false;

        for (var p = 0; p < n; p++) {
          if (combination[p] !== 0) {
            isContinue = true;
          }
        }
      } while (isContinue);

      this.products = products;
    },
    handleProductShow: function handleProductShow(row) {
      this.productForm = Object.assign({}, row);
      this.productVisiable = true;
    },
    uploadProductUrl: function uploadProductUrl(response) {
      this.productForm.url = response.d.url;
    },
    handleProductEdit: function handleProductEdit() {
      for (var i = 0; i < this.products.length; i++) {
        var v = this.products[i];

        if (v.id === this.productForm.id) {
          this.products.splice(i, 1, this.productForm);
          break;
        }
      }

      this.productVisiable = false;
    },
    handleAttributeShow: function handleAttributeShow() {
      this.attributeForm = {};
      this.attributeVisiable = true;
    },
    handleAttributeAdd: function handleAttributeAdd() {
      this.attributes.unshift(this.attributeForm);
      this.attributeVisiable = false;
    },
    handleAttributeDelete: function handleAttributeDelete(row) {
      var index = this.Indicators.indexOf(row);
      this.attributes.splice(index, 1);
    },
    handleKeyIndicatorsShow: function handleKeyIndicatorsShow() {
      // this.keyIndicators = []
      this.keyIndicatorsVisiable = true;
    },
    handleKeyIndicatorsAdd: function handleKeyIndicatorsAdd() {
      this.keyIndicators.unshift(this.keyIndicatorsForm);
      this.keyIndicatorsVisiable = false;
    },
    handleKeyIndicatorsDelete: function handleKeyIndicatorsDelete(row) {
      var index = this.keyIndicators.indexOf(row);
      this.keyIndicators.splice(index, 1);
    }
  }
};
exports.default = _default;