"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listBrand = listBrand;
exports.createBrand = createBrand;
exports.readBrand = readBrand;
exports.updateBrand = updateBrand;
exports.deleteBrand = deleteBrand;

var _request = _interopRequireDefault(require("@/utils/request"));

function listBrand(query) {
  return (0, _request.default)({
    url: '/brand/list',
    method: 'get',
    params: query
  });
}

function createBrand(data) {
  return (0, _request.default)({
    url: '/brand/create',
    method: 'post',
    data: data
  });
}

function readBrand(data) {
  return (0, _request.default)({
    url: '/brand/read',
    method: 'get',
    data: data
  });
}

function updateBrand(data) {
  return (0, _request.default)({
    url: '/brand/update',
    method: 'post',
    data: data
  });
}

function deleteBrand(data) {
  return (0, _request.default)({
    url: '/brand/delete',
    method: 'post',
    data: data
  });
}