"use strict";

var _interopRequireWildcard = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("/root/zbyx-mall/admin/mall-admin/node_modules/_core-js@2.6.11@core-js/modules/es6.array.iterator.js");

require("/root/zbyx-mall/admin/mall-admin/node_modules/_core-js@2.6.11@core-js/modules/es6.promise.js");

require("/root/zbyx-mall/admin/mall-admin/node_modules/_core-js@2.6.11@core-js/modules/es6.object.assign.js");

require("/root/zbyx-mall/admin/mall-admin/node_modules/_core-js@2.6.11@core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _moment = _interopRequireDefault(require("moment"));

require("./icons");

require("./permission");

var filters = _interopRequireWildcard(require("./filters"));

var _index2 = _interopRequireDefault(require("@/directive/permission/index.js"));

var _print = _interopRequireDefault(require("@/utils/print"));

require("./directive/el-select-loadmore/el-select-loadmore");

// A modern alternative to CSS resets
// global css
// icon
// permission control
// global filters
// 权限判断指令
// 打印
_vue.default.use(_print.default);

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size

});

_vue.default.prototype.$moment = function (val, isTime) {
  var format = 'YYYY-MM-DD HH:mm:ss';

  if (isTime) {
    format = 'YYYY-MM-DD';
  }

  return (0, _moment.default)(parseInt(val)).format(format);
};

_vue.default.directive('permission', _index2.default); // register global utility filters.


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});