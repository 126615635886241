"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Page404',
  data: function data() {
    return {
      list: [{
        name: '新增用户',
        value: '299'
      }, {
        name: '新增会员',
        value: '299'
      }, {
        name: '活跃用户',
        value: '299'
      }, {
        name: '新增订单量',
        value: '299'
      }, {
        name: '累计用户',
        value: '299'
      }, {
        name: '累计会员',
        value: '299'
      }, {
        name: '月活用户',
        value: '299'
      }, {
        name: '累计订单量',
        value: '299'
      }],
      totalList: [{
        name: '累计用户',
        value: '299'
      }, {
        name: '累计会员',
        value: '299'
      }, {
        name: '月活用户',
        value: '299'
      }, {
        name: '累计订单量',
        value: '299'
      }],
      value1: ''
    };
  }
};
exports.default = _default;