"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _topic = require("@/api/topic");

var _goods = require("@/api/goods");

var _storage = require("@/api/storage");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _auth = require("@/utils/auth");

var _validate = require("@/utils/validate");

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = {
  name: 'TopicEdit',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      id: 0,
      topic: {
        title: '',
        subtitle: '',
        autoTimeEnabled: false,
        timeValue: [],
        picUrl: '',
        goods: []
      },
      timeValue: [],
      goodsList: [],
      addVisiable: false,
      list: [],
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 5,
        goodsId: undefined,
        goodsSn: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      selectedlist: [],
      rules: {
        title: [{
          required: true,
          message: '活动名不能为空',
          trigger: 'blur'
        }],
        subtitle: [{
          required: true,
          message: '活动描述不能为空',
          trigger: 'blur'
        }],
        // content: [
        //   { required: true, message: '专题内容不能为空', trigger: 'blur' }
        // ],
        // price: [
        //   { required: true, message: '专题低价不能为空', trigger: 'blur' }
        // ],
        timeValue: [{
          required: true,
          type: 'data',
          trigger: 'change',
          validator: _validate.isDatePicker
        }]
      } // editorInit: {
      //   language: 'zh_CN',
      //   convert_urls: false,
      //   height: 500,
      //   plugins: [
      //     'advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'
      //   ],
      //   toolbar: [
      //     'searchreplace bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample',
      //     'hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'
      //   ],
      //   images_upload_handler: function(blobInfo, success, failure) {
      //     const formData = new FormData()
      //     formData.append('file', blobInfo.blob())
      //     createStorage(formData)
      //       .then(res => {
      //         success(res.data.d.url)
      //       })
      //       .catch(() => {
      //         failure('上传失败，请重新上传')
      //       })
      //   }
      // }

    };
  },
  computed: {
    headers: function headers() {
      return {
        'x-auth-token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {},
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _goods.listGoods)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          var data = response.data.d.list;
          data.forEach(function (item, index) {
            item.add = true;

            if (_this.goodsList && _this.goodsList !== null) {
              _this.goodsList.forEach(function (val, k) {
                if (item.id === val.id) {
                  item.add = false;
                }
              });
            }
          });
          _this.list = data;

          if (response.data.d.totalPage) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val, 111111); // this.selectedlist = val
    },
    uploadPicUrl: function uploadPicUrl(response) {
      this.topic.picUrl = response.d.url;
      console.log(this.topic.picUrl); // this.$set(this.topic, 'picUrl', response.d.url)
    },
    getChangeTime: function getChangeTime(t) {
      var startTime = t[0];
      var endTime = t[1];
      this.topic.startTime = (0, _moment.default)(startTime).format('YYYY-MM-DD HH:mm:ss');
      this.topic.endTime = (0, _moment.default)(endTime).format('YYYY-MM-DD HH:mm:ss');
    },
    handleCreate: function handleCreate() {
      this.listQuery = {
        page: 1,
        limit: 5,
        id: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      };
      this.list = [];
      this.total = 0;
      this.selectedlist = [];
      this.addVisiable = true;
      this.listQuery.page = 1;
      this.getList();
    },
    confirmAdd: function confirmAdd() {
      var _this2 = this;

      var newGoodsIds = [];
      var newGoodsList = [];
      this.selectedlist.forEach(function (item) {
        var id = item.id;
        var found = false;

        _this2.goodsList.forEach(function (goodsId) {
          if (id === goodsId) {
            found = true;
          }
        });

        if (!found) {
          newGoodsIds.push(id);
          newGoodsList.push(item);
        }
      });

      if (newGoodsIds.length > 0) {
        this.topic.goods = this.topic.goods.concat(newGoodsIds);
        this.goodsList = this.goodsList.concat(newGoodsList);
      }

      this.addVisiable = false;
    },
    handleDelete: function handleDelete(row) {
      for (var index = 0; index < this.topic.goods.length; index++) {
        if (row.id === this.topic.goods[index]) {
          this.topic.goods.splice(index, 1);
        }
      }

      for (var index2 = 0; index2 < this.goodsList.length; index2++) {
        if (row.id === this.goodsList[index2].id) {
          this.goodsList.splice(index2, 1);
        }
      }
    },
    handleAdd: function handleAdd(row) {
      var _this3 = this;

      this.selectedlist.push(row);
      var data = this.list;
      data.forEach(function (item, index) {
        _this3.selectedlist.forEach(function (val, k) {
          if (item.id === val.id) {
            item.add = false;
          }
        });
      });
      this.list = data;
    },
    handleCancel: function handleCancel() {
      this.$router.push({
        path: '/promotion/topic'
      });
    },
    handleConfirm: function handleConfirm() {
      var _this4 = this;

      this.$refs['topic'].validate(function (valid) {
        if (valid) {
          delete _this4.topic.timeValue;
          (0, _topic.createTopic)(_this4.topic).then(function (response) {
            _this4.$router.push({
              path: '/promotion/topic'
            });
          }).catch(function (response) {
            _this4.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    }
  }
};
exports.default = _default;