"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = checkPermission;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _store = _interopRequireDefault(require("@/store"));

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    var perms = _store.default.getters && _store.default.getters.perms;
    var permissions = value;
    var hasPermission = false;

    if (perms.indexOf('*') >= 0) {
      hasPermission = true;
    } else {
      hasPermission = perms.some(function (perm) {
        return permissions.includes(perm);
      });
    }

    if (!hasPermission) {
      return false;
    }

    return true;
  } else {
    console.error("need perms! Like v-permission=\"['GET /aaa','POST /bbb']\"");
    return false;
  }
}