"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listRecord = listRecord;
exports.listGroupon = listGroupon;
exports.deleteGroupon = deleteGroupon;
exports.publishGroupon = publishGroupon;
exports.editGroupon = editGroupon;

var _request = _interopRequireDefault(require("@/utils/request"));

function listRecord(query) {
  return (0, _request.default)({
    url: '/groupon/listRecord',
    method: 'get',
    params: query
  });
}

function listGroupon(query) {
  return (0, _request.default)({
    url: '/groupon/list',
    method: 'get',
    params: query
  });
}

function deleteGroupon(data) {
  return (0, _request.default)({
    url: '/groupon/delete',
    method: 'post',
    data: data
  });
}

function publishGroupon(data) {
  return (0, _request.default)({
    url: '/groupon/create',
    method: 'post',
    data: data
  });
}

function editGroupon(data) {
  return (0, _request.default)({
    url: '/groupon/update',
    method: 'post',
    data: data
  });
}