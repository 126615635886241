"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listAd = listAd;
exports.createAd = createAd;
exports.readAd = readAd;
exports.updateAd = updateAd;
exports.deleteAd = deleteAd;
exports.listDayRecommend = listDayRecommend;
exports.createDayRecommend = createDayRecommend;
exports.updateDayRecommend = updateDayRecommend;
exports.deleteDayRecommend = deleteDayRecommend;
exports.listSelected = listSelected;
exports.createSelected = createSelected;
exports.updateSelected = updateSelected;
exports.deleteSelected = deleteSelected;
exports.listDayOption = listDayOption;
exports.createDayOption = createDayOption;
exports.updateDayOption = updateDayOption;
exports.deleteDayOption = deleteDayOption;

var _request = _interopRequireDefault(require("@/utils/request"));

function listAd(query) {
  return (0, _request.default)({
    url: '/ad/list',
    method: 'get',
    params: query
  });
}

function createAd(data) {
  return (0, _request.default)({
    url: '/ad/create',
    method: 'post',
    data: data
  });
}

function readAd(data) {
  return (0, _request.default)({
    url: '/ad/read',
    method: 'get',
    data: data
  });
}

function updateAd(data) {
  return (0, _request.default)({
    url: '/ad/update',
    method: 'post',
    data: data
  });
}

function deleteAd(data) {
  return (0, _request.default)({
    url: '/ad/delete',
    method: 'post',
    data: data
  });
}

function listDayRecommend(query) {
  return (0, _request.default)({
    url: '/index_day_selected/list',
    method: 'get',
    params: query
  });
}

function createDayRecommend(data) {
  return (0, _request.default)({
    url: '/index_day_selected/create',
    method: 'post',
    data: data
  });
}

function updateDayRecommend(data) {
  return (0, _request.default)({
    url: '/index_day_selected/update',
    method: 'post',
    data: data
  });
}

function deleteDayRecommend(data) {
  return (0, _request.default)({
    url: '/index_day_selected/delete',
    method: 'post',
    data: data
  });
}

function listSelected(query) {
  return (0, _request.default)({
    url: '/selected/list',
    method: 'get',
    params: query
  });
}

function createSelected(data) {
  return (0, _request.default)({
    url: '/selected/create',
    method: 'post',
    data: data
  });
}

function updateSelected(data) {
  return (0, _request.default)({
    url: '/selected/update',
    method: 'post',
    data: data
  });
}

function deleteSelected(data) {
  return (0, _request.default)({
    url: '/selected/delete',
    method: 'post',
    data: data
  });
}

function listDayOption(query) {
  return (0, _request.default)({
    url: '/day_selected/list',
    method: 'get',
    params: query
  });
}

function createDayOption(data) {
  return (0, _request.default)({
    url: '/day_selected/create',
    method: 'post',
    data: data
  });
}

function updateDayOption(data) {
  return (0, _request.default)({
    url: '/day_selected/update',
    method: 'post',
    data: data
  });
}

function deleteDayOption(data) {
  return (0, _request.default)({
    url: '/day_selected/delete',
    method: 'post',
    data: data
  });
}