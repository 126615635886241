"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listCategory = listCategory;
exports.listCatL1 = listCatL1;
exports.createCategory = createCategory;
exports.readCategory = readCategory;
exports.updateCategory = updateCategory;
exports.deleteCategory = deleteCategory;

var _request = _interopRequireDefault(require("@/utils/request"));

function listCategory(query) {
  return (0, _request.default)({
    url: '/category/list',
    method: 'get',
    params: query
  });
}

function listCatL1() {
  return (0, _request.default)({
    url: '/category/l1',
    method: 'get'
  });
}

function createCategory(data) {
  return (0, _request.default)({
    url: '/category/create',
    method: 'post',
    data: data
  });
}

function readCategory(data) {
  return (0, _request.default)({
    url: '/category/read',
    method: 'get',
    data: data
  });
}

function updateCategory(data) {
  return (0, _request.default)({
    url: '/category/update',
    method: 'post',
    data: data
  });
}

function deleteCategory(data) {
  return (0, _request.default)({
    url: '/category/delete',
    method: 'post',
    data: data
  });
}