"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.regexp.to-string");

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

require("core-js/modules/es6.function.name");

var _ad = require("@/api/ad");

var _storage = require("@/api/storage");

var _auth = require("@/utils/auth");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

// Secondary package based on el-pagination
var _default = {
  name: 'Ad',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        id: undefined,
        name: undefined,
        content: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      dataForm: {
        id: undefined,
        name: undefined,
        content: undefined,
        url: undefined,
        link: undefined,
        idValue: undefined,
        position: 1,
        type: 1,
        enabled: true
      },
      chooseData: {
        type: ['跳转链接', '商品id', '活动id', '页面路径'],
        pages: [{
          value: '/subpage/live-push/index',
          label: '直播页'
        } // {
        //   value: '/pages/mall/index',
        //   label: '购物车'
        // },
        // {
        //   value: '/pages/userCenter/index',
        //   label: '我的'
        // }
        ]
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{
          required: true,
          message: '广告标题不能为空',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '广告内容不能为空',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '广告链接不能为空',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  computed: {
    headers: function headers() {
      return {
        'x-auth-token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _ad.listAd)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          if (response.data.d.list && response.data.d.list !== null && response.data.d.list.length > 0) {
            _this.list = response.data.d.list;
          }

          if (response.data.d.totalPage) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: undefined,
        content: undefined,
        url: undefined,
        link: undefined,
        position: 1,
        type: 0,
        enabled: true,
        idValue: undefined
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    uploadUrl: function uploadUrl(response) {
      console.log('图片上传完成的url', response);
      this.dataForm.url = response.d.url;
    },
    checkFileSize: function checkFileSize(file) {
      if (file.size > 1048576) {
        this.$message.error("".concat(file.name, "\u6587\u4EF6\u5927\u4E8E1024KB\uFF0C\u8BF7\u9009\u62E9\u5C0F\u4E8E1024KB\u5927\u5C0F\u7684\u56FE\u7247"));
        return false;
      }

      return true;
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _ad.createAd)(_this3.dataForm).then(function (response) {
            // this.list.unshift(response.data.d)
            _this3.getList();

            _this3.dialogFormVisible = false;

            _this3.$notify.success({
              title: '成功',
              message: '创建成功'
            });
          }).catch(function (response) {
            _this3.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.dataForm = Object.assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _ad.updateAd)(_this5.dataForm).then(function () {
            var _iterator = _createForOfIteratorHelper(_this5.list),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;

                if (v.id === _this5.dataForm.id) {
                  var index = _this5.list.indexOf(v);

                  _this5.list.splice(index, 1, _this5.dataForm);

                  break;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }

            _this5.dialogFormVisible = false;

            _this5.$notify.success({
              title: '成功',
              message: '更新广告成功'
            });
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      (0, _ad.deleteAd)(row).then(function (response) {
        _this6.$notify.success({
          title: '成功',
          message: '删除成功'
        });

        var index = _this6.list.indexOf(row);

        _this6.list.splice(index, 1);
      }).catch(function (response) {
        _this6.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this7 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['广告ID', '广告标题', '广告内容', '广告图片', '广告位置', '活动链接', '是否启用'];
        var filterVal = ['id', 'name', 'content', 'url', 'postion', 'link', 'enabled'];
        excel.export_json_to_excel2(tHeader, _this7.list, filterVal, '广告信息');
        _this7.downloadLoading = false;
      });
    }
  }
};
exports.default = _default;