"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  timeout: 5000 // request timeout

}); // request interceptor


service.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (_store.default.getters.token) {
    // 让每个请求携带token-- ['x-auth-token']为自定义key 请根据实际情况自行修改
    config.headers['x-auth-token'] = (0, _auth.getToken)();
  }

  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug

  Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(function (response) {
  var res = response.data;

  if (res.c === 501) {
    _elementUi.MessageBox.alert('系统未登录，请重新登录', '错误', {
      confirmButtonText: '确定',
      type: 'error'
    }).then(function () {
      _store.default.dispatch('FedLogOut').then(function () {
        location.reload();
      });
    });

    return Promise.reject('error');
  } else if (res.c === 502) {
    _elementUi.MessageBox.alert(res.m, '错误', {
      confirmButtonText: '确定',
      type: 'error'
    });

    return Promise.reject('error');
  } else if (res.c === 503) {
    _elementUi.MessageBox.alert('请求业务目前未支持', '警告', {
      confirmButtonText: '确定',
      type: 'error'
    });

    return Promise.reject('error');
  } else if (res.c === 504) {
    _elementUi.MessageBox.alert('更新数据已经失效，请刷新页面重新操作', '警告', {
      confirmButtonText: '确定',
      type: 'error'
    });

    return Promise.reject('error');
  } else if (res.c === 505) {
    _elementUi.MessageBox.alert('更新失败，请再尝试一次', '警告', {
      confirmButtonText: '确定',
      type: 'error'
    });

    return Promise.reject('error');
  } else if (res.c === 506) {
    _elementUi.MessageBox.alert('没有操作权限，请联系管理员授权', '错误', {
      confirmButtonText: '确定',
      type: 'error'
    });

    return Promise.reject('error');
  } else if (res.c !== 0) {
    // 非5xx的错误属于业务错误，留给具体页面处理
    return Promise.reject(response);
  } else {
    return response;
  }
}, function (error) {
  console.log(error, 11111); // for debug

  (0, _elementUi.Message)({
    message: '登录连接超时（请重新登陆）',
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;