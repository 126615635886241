"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

var _defineProperty2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/defineProperty"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _router = require("@/router");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * 通过meta.perms判断是否与当前用户权限匹配
 * @param perms
 * @param route
 */
function hasPermission(perms, route) {
  if (route.meta && route.meta.perms) {
    return perms.some(function (perm) {
      return route.meta.perms.includes(perm);
    });
  } else {
    return true;
  }
}
/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param perms
 */


function filterAsyncRouter(routes, perms) {
  var res = []; // console.log(routes, perms, '过滤路由-----------------')

  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (tmp.children) {
      tmp.children = filterAsyncRouter(tmp.children, perms);

      if (tmp.children && tmp.children.length > 0) {
        res.push(tmp);
      }
    } else {
      if (hasPermission(perms, tmp)) {
        res.push(tmp);
      }
    }
  });
  return res;
}

var permission = {
  state: {
    routers: _router.constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = _router.constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        var perms = data.perms;
        var accessedRouters;

        if (perms.includes('*')) {
          accessedRouters = _router.asyncRouterMap;
        } else {
          accessedRouters = filterAsyncRouter(_router.asyncRouterMap, perms);
        }

        commit('SET_ROUTERS', accessedRouters);
        resolve();
      });
    }
  }
};
var _default = permission;
exports.default = _default;