var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "homeIndex" }, [
    _c(
      "ul",
      { staticClass: "box1" },
      _vm._l(_vm.list, function(item, index) {
        return _c("li", { key: index }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "number" }, [_vm._v(_vm._s(item.value))]),
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.name))])
          ])
        ])
      }),
      0
    ),
    _c("div", { staticClass: "split-line" }),
    _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "datepick" }, [
        _c(
          "div",
          { staticClass: "date-box" },
          [
            _c("el-date-picker", {
              attrs: {
                type: "datetimerange",
                "unlink-panels": true,
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期"
              },
              model: {
                value: _vm.value1,
                callback: function($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1"
              }
            }),
            _c(
              "el-button",
              { staticClass: "searchBtn", attrs: { type: "primary" } },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ])
    ]),
    _c(
      "ul",
      { staticClass: "box2" },
      _vm._l(_vm.totalList, function(item, index) {
        return _c("li", { key: index }, [
          _c("div", { staticClass: "item" }, [
            _c("div", { staticClass: "number" }, [_vm._v(_vm._s(item.value))]),
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.name))])
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }