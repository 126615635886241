"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listRegion = listRegion;
exports.listSubRegion = listSubRegion;

var _request = _interopRequireDefault(require("@/utils/request"));

function listRegion() {
  return (0, _request.default)({
    url: '/region/list',
    method: 'get'
  });
}

function listSubRegion(query) {
  return (0, _request.default)({
    url: '/region/clist',
    method: 'get',
    params: query
  });
}