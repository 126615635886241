"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _topic = require("@/api/topic");

var _BackToTop = _interopRequireDefault(require("@/components/BackToTop"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _lodash = _interopRequireDefault(require("lodash"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = {
  name: 'Topic',
  components: {
    BackToTop: _BackToTop.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        title: undefined,
        subtitle: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      options: [{
        value: 'id',
        label: '按序号排序'
      }, {
        value: 'add_time',
        label: '按时间排序'
      }, {
        value: 'price',
        label: '按价格排序'
      }],
      multipleSelection: [],
      contentDetail: '',
      contentDialogVisible: false,
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _topic.listTopic)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this.list = response.data.d.list;

          if (response.data.d.totalPage) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    turnOn: function turnOn(row) {
      this.getTopic();
    },
    getTopic: function getTopic(row) {
      var _this2 = this;

      this.listLoading = true;
      (0, _topic.readTopic)({
        id: row.id
      }).then(function (response) {
        if (response && response.data && response.data.d) {
          _this2.topic = response.data.d.topic;

          if (_this2.topic.enabled) {
            _this2.topic.enabled = false;
          } else {
            _this2.topic.enabled = true;
          }

          (0, _topic.updateTopic)(_this2.topic).then(function (response) {
            _this2.$router.push({
              path: '/promotion/topic'
            });
          }).catch(function (response) {
            _this2.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }

        _this2.listLoading = false;
      }).catch(function () {
        _this2.topic = {};
        _this2.goodsList = [];
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleCreate: function handleCreate() {
      this.$router.push({
        path: '/promotion/topic-create'
      });
    },
    handleUpdate: function handleUpdate(row) {
      this.$router.push({
        path: '/promotion/topic-edit',
        query: {
          id: row.id
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this3 = this;

      (0, _topic.deleteTopic)(row).then(function (response) {
        _this3.$notify.success({
          title: '成功',
          message: '删除专题成功'
        });

        var index = _this3.list.indexOf(row);

        _this3.list.splice(index, 1);
      }).catch(function (response) {
        _this3.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showContent: function showContent(content) {
      this.contentDetail = content;
      this.contentDialogVisible = true;
    },
    handleBatchDelete: function handleBatchDelete() {
      var _this4 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择至少一条记录');
        return;
      }

      var ids = [];

      _lodash.default.forEach(this.multipleSelection, function (item) {
        ids.push(item.id);
      });

      (0, _topic.batchDeleteTopic)({
        ids: ids
      }).then(function (response) {
        _this4.$notify.success({
          title: '成功',
          message: '批量删除专题成功'
        });

        _this4.getList();
      }).catch(function (response) {
        _this4.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this5 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['专题ID', '专题标题', '专题子标题', '专题内容', '专题图片', '商品低价', '阅读量', '专题商品'];
        var filterVal = ['id', 'title', 'subtitle', 'content', 'picUrl', 'price', 'readCount', 'goods'];
        excel.export_json_to_excel2(tHeader, _this5.list, filterVal, '专题信息');
        _this5.downloadLoading = false;
      });
    }
  }
};
exports.default = _default;