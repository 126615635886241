"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _coupon = require("@/api/coupon");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var defaultTypeOptions = [{
  label: '通用领券',
  value: 0
}, {
  label: '注册赠券',
  value: 1
}, {
  label: '兑换码',
  value: 2
}];
var defaultUseStatusOptions = [{
  label: '未使用',
  value: 0
}, {
  label: '已使用',
  value: 1
}, {
  label: '已过期',
  value: 2
}, {
  label: '已下架',
  value: 3
}];
var _default = {
  name: 'CouponDetail',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatType: function formatType(type) {
      for (var i = 0; i < defaultTypeOptions.length; i++) {
        if (type === defaultTypeOptions[i].value) {
          return defaultTypeOptions[i].label;
        }
      }

      return '';
    },
    formatGoodsType: function formatGoodsType(goodsType) {
      if (goodsType === 0) {
        return '全场通用';
      } else if (goodsType === 1) {
        return '指定分类';
      } else {
        return '指定商品';
      }
    },
    formatStatus: function formatStatus(status) {
      if (status === 0) {
        return '正常';
      } else if (status === 1) {
        return '已过期';
      } else {
        return '已下架';
      }
    },
    formatUseStatus: function formatUseStatus(status) {
      if (status === 0) {
        return '未使用';
      } else if (status === 1) {
        return '已使用';
      } else if (status === 3) {
        return '已过期';
      } else {
        return '已下架';
      }
    }
  },
  data: function data() {
    return {
      typeOptions: Object.assign({}, defaultTypeOptions),
      useStatusOptions: Object.assign({}, defaultUseStatusOptions),
      coupon: {},
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        couponId: 0,
        userId: undefined,
        status: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      if (this.$route.query.id == null) {
        return;
      }

      (0, _coupon.readCoupon)(this.$route.query.id).then(function (response) {
        _this.coupon = response.data.d;
      });
      this.listQuery.couponId = this.$route.query.id;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _coupon.listCouponUser)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this2.list = response.data.d.list;

          if (response.data.d.totalPage) {
            _this2.total = response.data.d.totalPage;
          }
        }

        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    getTimeScope: function getTimeScope() {
      if (this.coupon.timeType === 0) {
        return '领取' + this.coupon.days + '天有效';
      } else if (this.coupon.timeType === 1) {
        return '自' + this.coupon.startTime + '至' + this.coupon.endTime + '有效';
      } else {
        return '未知';
      }
    },
    getGoodsScope: function getGoodsScope() {}
  }
};
exports.default = _default;