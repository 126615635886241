"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _order = require("@/api/order");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _permission = _interopRequireDefault(require("@/utils/permission"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
// 权限判断函数
var statusMap = {
  101: '未付款',
  102: '用户取消',
  103: '系统取消',
  201: '已付款',
  202: '申请退款',
  203: '已退款',
  301: '已发货',
  401: '用户收货',
  402: '系统收货'
};
var _default = {
  name: 'Order',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    orderStatusFilter: function orderStatusFilter(status) {
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      modifyShipDialogVisible: false,
      listQuery: {
        page: 1,
        limit: 20,
        id: undefined,
        name: undefined,
        timeArray: [],
        orderStatusArray: [],
        sort: 'add_time',
        order: 'desc'
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      statusMap: statusMap,
      orderDialogVisible: false,
      orderDetail: {
        order: {},
        user: {},
        orderGoods: []
      },
      shipForm: {
        orderId: undefined,
        shipChannel: undefined,
        shipSn: undefined
      },
      shipDialogVisible: false,
      refundForm: {
        orderId: undefined,
        refundMoney: undefined
      },
      refundDialogVisible: false,
      downloadLoading: false,
      channels: []
    };
  },
  created: function created() {
    this.getList();
    this.getChannel();
  },
  methods: {
    checkPermission: _permission.default,
    getList: function getList() {
      var _this = this;

      this.listLoading = true;

      if (this.listQuery.timeArray && this.listQuery.timeArray.length === 2) {
        this.listQuery.start = this.listQuery.timeArray[0];
        this.listQuery.end = this.listQuery.timeArray[1];
      } else {
        this.listQuery.start = null;
        this.listQuery.end = null;
      }

      (0, _order.listOrder)(this.listQuery).then(function (response) {
        if (response && response.data.d) {
          _this.list = response.data.d.list;

          if (response.data.d.totalPage) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    getChannel: function getChannel() {
      var _this2 = this;

      (0, _order.listChannel)().then(function (response) {
        _this2.channels = response.data.d;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleDetail: function handleDetail(row) {
      var _this3 = this;

      (0, _order.detailOrder)(row.id).then(function (response) {
        _this3.orderDetail = response.data.d;
      });
      this.orderDialogVisible = true;
    },
    handleShip: function handleShip(row) {
      var _this4 = this;

      this.shipForm.orderId = row.id;
      this.shipForm.shipChannel = row.shipChannel;
      this.shipForm.shipSn = row.shipSn;
      this.shipDialogVisible = true;
      this.$nextTick(function () {
        _this4.$refs['shipForm'].clearValidate();
      });
    },
    confirmShip: function confirmShip() {
      var _this5 = this;

      this.$refs['shipForm'].validate(function (valid) {
        if (valid) {
          (0, _order.shipOrder)(_this5.shipForm).then(function (response) {
            _this5.shipDialogVisible = false;

            _this5.$notify.success({
              title: '成功',
              message: '确认发货成功'
            });

            _this5.getList();
          }).catch(function (response) {
            _this5.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    confirmModifyShip: function confirmModifyShip() {
      var _this6 = this;

      this.$refs['modifyShipForm'].validate(function (valid) {
        if (valid) {
          (0, _order.shipOrder)(_this6.shipForm).then(function (response) {
            _this6.shipDialogVisible = false;

            _this6.$notify.success({
              title: '成功',
              message: '修改快递单号成功'
            });

            _this6.getList();
          }).catch(function (response) {
            _this6.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this7 = this;

      (0, _order.deleteOrder)({
        orderId: row.id
      }).then(function (response) {
        _this7.$notify.success({
          title: '成功',
          message: '订单删除成功'
        });

        _this7.getList();
      }).catch(function (response) {
        _this7.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleRefund: function handleRefund(row) {
      var _this8 = this;

      this.refundForm.orderId = row.id;
      this.refundForm.refundMoney = row.actualPrice;
      this.refundDialogVisible = true;
      this.$nextTick(function () {
        _this8.$refs['refundForm'].clearValidate();
      });
    },
    confirmRefund: function confirmRefund() {
      var _this9 = this;

      this.$refs['refundForm'].validate(function (valid) {
        if (valid) {
          (0, _order.refundOrder)(_this9.refundForm).then(function (response) {
            _this9.refundDialogVisible = false;

            _this9.$notify.success({
              title: '成功',
              message: '确认退款成功'
            });

            _this9.getList();
          }).catch(function (response) {
            _this9.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleDownload: function handleDownload() {
      var _this10 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['订单ID', '订单编号', '用户ID', '订单状态', '是否删除', '收货人', '收货联系电话', '收货地址'];
        var filterVal = ['id', 'orderSn', 'userId', 'orderStatus', 'isDelete', 'consignee', 'mobile', 'address'];
        excel.export_json_to_excel2(tHeader, _this10.list, filterVal, '订单信息');
        _this10.downloadLoading = false;
      });
    },
    printOrder: function printOrder() {
      this.$print(this.$refs.print);
      this.orderDialogVisible = false;
    }
  }
};
exports.default = _default;