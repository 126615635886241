"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/es6.array.from");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.to-string");

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.set");

var _toConsumableArray2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

var _coupon = require("@/api/coupon");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _storage = require("@/api/storage");

var _auth = require("@/utils/auth");

var _goods = require("@/api/goods");

var _validate = require("@/utils/validate");

var _moment = _interopRequireDefault(require("moment"));

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var defaultTypeOptions = [{
  label: '通用领券',
  value: 0
}, {
  label: '注册赠券',
  value: 1
}, {
  label: '兑换码',
  value: 2
}];
var defaultStatusOptions = [{
  label: '正常',
  value: 0
}, {
  label: '已过期',
  value: 1
}, {
  label: '已下架',
  value: 2
}];
var _default = {
  name: 'Coupon',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    formatType: function formatType(type) {
      for (var i = 0; i < defaultTypeOptions.length; i++) {
        if (type === defaultTypeOptions[i].value) {
          return defaultTypeOptions[i].label;
        }
      }

      return '';
    },
    formatGoodsType: function formatGoodsType(goodsType) {
      if (goodsType === 0) {
        return '全场通用';
      } else if (goodsType === 1) {
        return '指定分类';
      } else {
        return '指定商品';
      }
    },
    formatStatus: function formatStatus(status) {
      if (status === 0) {
        return '正常';
      } else if (status === 1) {
        return '已过期';
      } else {
        return '已下架';
      }
    }
  },
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      typeOptions: Object.assign({}, defaultTypeOptions),
      statusOptions: Object.assign({}, defaultStatusOptions),
      list: [],
      total: 1,
      radio1: '0',
      conditionVisiable: false,
      checked0: true,
      checked1: false,
      goodsList: [],
      listQuery: {
        page: 1,
        limit: 15,
        name: undefined,
        id: undefined,
        desc: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      tableData: [],
      listLoading: true,
      addVisiable: false,
      timeValue: [],
      bindList: {
        page: 1,
        goodsId: undefined,
        goodsSn: undefined,
        name: undefined,
        limit: 5
      },
      dataForm: {
        id: '',
        name: '',
        tag: '',
        // 优惠券tag
        total: 0,
        // 优惠券数量，如果是0，则是无限量
        discount: 0,
        // 优惠金额
        discountType: 1,
        // 优惠类型0默认，1满减，2折扣
        min: 0,
        // 最少消费金额才能使用优惠券。
        limit: 1,
        // 用户领券限制数量，如果是0，则是不限制；默认是1，限领一张.
        type: 0,
        status: 0,
        goodsType: 2,
        // 0是全部商品， 1 类目限制，2是商品限制
        goodsValue: [],
        // 商品限制值
        timeType: 0,
        days: 0,
        imageUrl: '',
        // 优惠券图片
        startTime: '',
        endTime: '',
        ccrossGoods: true,
        desc: '',
        timeValue: [],
        rules: [] // 优惠券优惠规则

      },
      confitionForm: {
        comment: '',
        countValue: '',
        discountValue: '',
        countType: '',
        discountType: ''
      },
      discountTableData: [],
      currentGoodsPage: 1,
      dialogFormVisible: false,
      dialogStatus: '',
      goodsPage: 1,
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{
          required: true,
          message: '优惠券标题不能为空',
          trigger: 'blur'
        }],
        timeValue: [{
          type: 'data',
          trigger: 'change',
          validator: _validate.isDatePicker
        }]
      },
      conditionRules: {
        comment: [{
          required: true,
          message: '优惠描述不能为空',
          trigger: 'blur'
        }],
        countValue: [{
          required: true,
          message: '优惠信息不能为空',
          trigger: 'blur'
        }],
        discountValue: [{
          required: true,
          message: '优惠信息不能为空',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  computed: {
    headers: function headers() {
      return {
        'x-auth-token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _coupon.listCoupon)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this.list = response.data.d.list;

          if (response.data.d.totalPage) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    getGoodsList: function getGoodsList() {
      var _this2 = this;

      this.listLoading = true;
      (0, _goods.listGoods)(this.bindList).then(function (response) {
        console.log(response, 1111111);

        if (response && response.data && response.data.d) {
          var data = response.data.d.list;
          data.forEach(function (item, index) {
            item.add = true;
            console.log(_this2.discountTableData, 222222);

            if (_this2.discountTableData && _this2.discountTableData !== null) {
              _this2.discountTableData.forEach(function (val, k) {
                if (item.id === val.id) {
                  item.add = false;
                }
              });
            }
          });
          _this2.goodsList = data;

          if (!data.end) {
            _this2.bindList.page += 1;
          }

          if (response.data.d.totalPage) {
            _this2.total = response.data.d.totalPage;
          }
        }

        _this2.listLoading = false;
      }).catch(function () {
        _this2.goodsList = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },
    getbindGoodsList: function getbindGoodsList(id) {
      var _this3 = this;

      this.listLoading = true;
      (0, _coupon.bindCouponList)(id).then(function (response) {
        if (response && response.data && response.data.d) {
          var data = response.data.d;
          _this3.discountTableData = data; // if (!data.end) {
          //   this.bindList.page += 1
          // }
          // if (response.data.d.totalPage) {
          //   this.total = response.data.d.totalPage
          // }
        }

        _this3.listLoading = false;
      }).catch(function () {
        _this3.goodsList = [];
        _this3.total = 0;
        _this3.listLoading = false;
      });
    },
    selectDiscountType: function selectDiscountType() {
      this.discountTableData = [];
    },
    getChangeValue: function getChangeValue(t) {
      console.log(t);
      var startTime = t[0];
      var endTime = t[1];
      this.dataForm.startTime = (0, _moment.default)(startTime).format('YYYY-MM-DD HH:mm:ss');
      this.dataForm.endTime = (0, _moment.default)(endTime).format('YYYY-MM-DD HH:mm:ss');
      console.log(this.dataForm.startTime, this.dataForm.endTime);
    },
    bindGoods: function bindGoods() {
      this.goodsList = [];
      this.total = 0;
      this.selectedlist = [];
      this.addVisiable = true;
      this.bindList = {
        page: 1,
        goodsId: undefined,
        goodsSn: undefined,
        name: undefined,
        limit: 5
      };
      this.getGoodsList();
    },
    addCondition: function addCondition() {
      this.confitionForm = {
        comment: '',
        countValue: 0,
        discountValue: 0,
        countType: 0,
        discountType: 1
      };
      this.conditionVisiable = true;
    },
    handleDelete1: function handleDelete1(row) {
      for (var index = 0; index < this.dataForm.goodsValue.length; index++) {
        if (row.id === this.dataForm.goodsValue[index]) {
          this.dataForm.goodsValue.splice(index, 1);
        }
      }

      for (var index2 = 0; index2 < this.discountTableData.length; index2++) {
        if (row.id === this.discountTableData[index2].id) {
          this.discountTableData.splice(index2, 1);
        }
      }
    },
    // 添加绑定商品
    handleAdd: function handleAdd(row) {
      var _this4 = this;

      console.log(this.selectedlist, 7777);
      this.selectedlist.push(row);
      var data = this.goodsList;
      data.forEach(function (item, index) {
        _this4.selectedlist.forEach(function (val, k) {
          if (item.id === val.id) {
            item.add = false;
          }
        });
      });
      this.goodsList = data;
    },
    confirmAddCondition: function confirmAddCondition() {
      var _this5 = this;

      this.$refs.confitionForm.validate(function (valid) {
        if (valid) {
          var confitionForm = _this5.confitionForm;

          if (_this5.dataForm.discountType === 1) {
            confitionForm.countType = 0;
            confitionForm.discountType = 1;
          } else if (_this5.dataForm.discountType === 2) {
            confitionForm.countType = 1;
            confitionForm.discountType = 2;
          }

          if (_this5.dialogStatus === 'update') {
            confitionForm.id = _this5.dataForm.id;
          }

          _this5.dataForm.rules.push(confitionForm);

          _this5.conditionVisiable = false;
        }
      });
    },
    closeConditionVisiable: function closeConditionVisiable() {
      this.confitionForm = {
        comment: '',
        countValue: 0,
        discountValue: 0,
        countType: 0,
        discountType: 1
      };
      this.conditionVisiable = false;
    },
    confirmAdd: function confirmAdd() {
      var _this6 = this;

      var newGoodsList = [];
      var newGoodsId = [];
      this.selectedlist.forEach(function (item) {
        var id = item.id;
        var found = false;
        console.log(_this6.discountTableData, 99999);

        _this6.discountTableData.forEach(function (val) {
          if (id === val.id) {
            found = true;
          }
        });

        if (!found) {
          newGoodsList.push(item);
          newGoodsId.push(item.id);
        }
      });

      if (newGoodsList.length > 0) {
        this.discountTableData = this.discountTableData.concat(newGoodsList);
        this.dataForm.goodsValue = (0, _toConsumableArray2.default)(new Set(this.dataForm.goodsValue.concat(newGoodsId)));
      }

      this.addVisiable = false;
    },
    closeAddVisiable: function closeAddVisiable() {
      this.selectedlist = [];
      this.bindList = {
        page: 1,
        goodsId: undefined,
        goodsSn: undefined,
        name: undefined,
        limit: 5
      };
      this.addVisiable = false;
    },
    uploadPicUrl: function uploadPicUrl(response) {
      if (response && response.c === 0) {
        var imageUrl = response.d.url;
        this.$set(this.dataForm, 'imageUrl', imageUrl);
      }
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleFilter1: function handleFilter1() {
      this.bindList.page = 1;
      this.getGoodsList();
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: '',
        name: '',
        tag: '',
        // 优惠券tag
        total: 0,
        // 优惠券数量，如果是0，则是无限量
        discount: 0,
        // 优惠金额
        discountType: 1,
        // 优惠类型0默认，1满减，2折扣
        min: 0,
        // 最少消费金额才能使用优惠券。
        limit: 1,
        // 用户领券限制数量，如果是0，则是不限制；默认是1，限领一张.
        type: 0,
        status: 0,
        goodsType: 0,
        // 0是全部商品， 1 类目限制，2是商品限制
        goodsValue: [],
        // 商品限制值
        timeType: 0,
        days: 0,
        imageUrl: '',
        // 优惠券图片
        startTime: '',
        endTime: '',
        ccrossGoods: true,
        desc: '',
        rules: [] // 优惠券优惠规则

      };
    },
    handleCreate: function handleCreate() {
      var _this7 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this7.$refs['dataForm'].clearValidate();
      });
      console.log(this.dataForm);
    },
    closeDialogFormVisible: function closeDialogFormVisible() {
      var _this8 = this;

      this.resetForm();
      this.discountTableData = [];
      this.dialogFormVisible = false;
      this.$nextTick(function () {
        _this8.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this9 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this9.dataForm.goodsValue = (0, _toConsumableArray2.default)(new Set(_this9.dataForm.goodsValue));
          (0, _coupon.createCoupon)(_this9.dataForm).then(function (response) {
            _this9.list.unshift(response.data.d);

            _this9.dialogFormVisible = false;

            _this9.$notify.success({
              title: '成功',
              message: '创建优惠券成功'
            });

            _this9.getList();

            _this9.resetForm();

            _this9.$refs['dataForm'].clearValidate();
          }).catch(function (response) {
            _this9.$notify.error({
              title: '失败',
              message: response.m
            });
          });
        }
      });
    },
    turnUp: function turnUp(row) {
      var _this10 = this;

      (0, _coupon.readCoupon)(row.id).then(function (response) {
        if (response.data.c === 0) {
          _this10.dataForm = Object.assign({}, response.data.d);
          _this10.dataForm.rules = response.data.d.ruleList;
          _this10.dialogStatus = 'update';

          if (_this10.dataForm.enabled) {
            _this10.dataForm.enabled = false;
          } else {
            _this10.dataForm.enabled = true;
          }

          (0, _coupon.updateCoupon)(_this10.dataForm).then(function () {
            var _iterator = _createForOfIteratorHelper(_this10.list),
                _step;

            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;

                if (v.id === _this10.dataForm.id) {
                  var index = _this10.list.indexOf(v);

                  _this10.list.splice(index, 1, _this10.dataForm);

                  break;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }

            _this10.dialogFormVisible = false;

            _this10.$notify.success({
              title: '成功',
              message: '更新优惠券成功'
            });

            _this10.getList();
          }).catch(function (response) {
            _this10.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this11 = this;

      (0, _coupon.readCoupon)(row.id).then(function (response) {
        console.log(response, 1111);

        if (response.data.c === 0) {
          _this11.dataForm = Object.assign({}, response.data.d);

          if (response.data.d.startTime && response.data.d.endTime) {
            _this11.$set(_this11.dataForm, 'timeValue', [response.data.d.startTime, response.data.d.endTime]);
          }

          _this11.dataForm.rules = response.data.d.ruleList;
          _this11.dialogStatus = 'update';
          _this11.dialogFormVisible = true;

          _this11.$nextTick(function () {
            if (_this11.dataForm.days === 0) {
              _this11.dataForm.daysType = 1;
            } else {
              _this11.dataForm.daysType = 0;
            }

            _this11.$refs['dataForm'].clearValidate();
          });

          _this11.getbindGoodsList(row.id);
        }
      });
    },
    updateData: function updateData() {
      var _this12 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this12.dataForm.goodsValue && _this12.dataForm.goodsValue.length > 0) {
            _this12.dataForm.goodsValue = (0, _toConsumableArray2.default)(new Set(_this12.dataForm.goodsValue));
          }

          (0, _coupon.updateCoupon)(_this12.dataForm).then(function () {
            var _iterator2 = _createForOfIteratorHelper(_this12.list),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var v = _step2.value;

                if (v.id === _this12.dataForm.id) {
                  var index = _this12.list.indexOf(v);

                  _this12.list.splice(index, 1, _this12.dataForm);

                  break;
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }

            _this12.dialogFormVisible = false;

            _this12.$notify.success({
              title: '成功',
              message: '更新优惠券成功'
            });

            _this12.getList();
          }).catch(function (response) {
            _this12.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this13 = this;

      (0, _coupon.deleteCoupon)(row).then(function (response) {
        _this13.$notify.success({
          title: '成功',
          message: '删除优惠券成功'
        });

        var index = _this13.list.indexOf(row);

        _this13.list.splice(index, 1);
      }).catch(function (response) {
        _this13.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({
        path: '/promotion/couponDetail',
        query: {
          id: row.id
        }
      });
    },
    handleDownload: function handleDownload() {
      var _this14 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['优惠券ID', '名称', '内容', '标签', '最低消费', '减免金额', '每人限领', '优惠券数量'];
        var filterVal = ['id', 'name', 'desc', 'tag', 'min', 'discount', 'limit', 'total'];
        excel.export_json_to_excel2(tHeader, _this14.list, filterVal, '优惠券信息');
        _this14.downloadLoading = false;
      });
    }
  }
};
exports.default = _default;