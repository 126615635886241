"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.asyncRouterMap = exports.default = exports.constantRouterMap = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _Layout = _interopRequireDefault(require("@/views/layout/Layout"));

_vue.default.use(_vueRouter.default);
/* Layout */


/** note: Submenu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    perms: ['GET /aaa','POST /bbb']     will control the page perms (you can set multiple perms)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/
var constantRouterMap = [{
  path: '/redirect',
  component: _Layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/authredirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/errorPage/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/errorPage/401'));
    });
  },
  hidden: true
}, {
  path: '/home',
  naem: 'home',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/home/index'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _Layout.default,
  redirect: '/user/usercommon'
} // {
//   path: '',
//   component: Layout,
//   redirect: 'dashboard',
//   children: [
//     {
//       path: 'dashboard',
//       component: () => import('@/views/dashboard/index'),
//       name: 'Dashboard',
//       meta: { title: '首页', icon: 'dashboard', affix: true }
//     }
//   ],
//   hidden: true
// }
];
exports.constantRouterMap = constantRouterMap;

var _default = new _vueRouter.default({
  // mode: 'history', // require service support
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});

exports.default = _default;
var asyncRouterMap = [{
  path: '/user',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'userManage',
  meta: {
    title: '用户管理',
    icon: 'peoples'
  },
  children: [{
    path: 'usercommon',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/usercommon'));
      });
    },
    name: 'usercommon',
    meta: {
      // perms: ['GET /admin/usercommon/list'],
      perms: ['GET /admin/user/list'],
      title: '用户管理',
      // 新增用户管理页面
      noCache: true
    }
  }, {
    path: 'user',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/user'));
      });
    },
    name: 'user',
    meta: {
      perms: ['GET /admin/user/list'],
      title: '会员管理',
      noCache: true
    }
  }, {
    path: 'employee',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/employee'));
      });
    },
    name: 'user',
    meta: {
      perms: ['GET /admin/user/list'],
      title: '员工管理',
      noCache: true
    }
  }, {
    path: 'address',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/address'));
      });
    },
    name: 'address',
    meta: {
      perms: ['GET /admin/address/list'],
      title: '收货地址',
      noCache: true
    }
  }, {
    path: 'footprint',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/footprint'));
      });
    },
    name: 'footprint',
    meta: {
      perms: ['GET /admin/footprint/list'],
      title: '用户足迹',
      noCache: true
    }
  }, {
    path: 'collect',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/collect'));
      });
    },
    name: 'collect',
    meta: {
      perms: ['GET /admin/collect/list'],
      title: '会员收藏',
      noCache: true
    },
    hidden: true
  }, {
    path: 'history',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/history'));
      });
    },
    name: 'history',
    meta: {
      perms: ['GET /admin/history/list'],
      title: '搜索历史',
      noCache: true
    },
    hidden: true
  }, {
    path: 'feedback',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/feedback'));
      });
    },
    name: 'feedback',
    meta: {
      perms: ['GET /admin/feedback/list'],
      title: '意见反馈',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/goods',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'goodsManage',
  meta: {
    title: '商品管理',
    icon: 'shopping'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/list'));
      });
    },
    name: 'goodsList',
    meta: {
      perms: ['GET /admin/goods/list', 'POST /admin/goods/delete'],
      title: '商品管理',
      noCache: true
    }
  }, {
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/create'));
      });
    },
    name: 'goodsCreate',
    meta: {
      perms: ['POST /admin/goods/create'],
      title: '商品上架',
      noCache: true
    },
    hidden: true
  }, {
    path: 'edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/edit'));
      });
    },
    name: 'goodsEdit',
    meta: {
      perms: ['GET /admin/goods/detail', 'POST /admin/goods/update', 'POST /admin/goods/catAndBrand'],
      title: '商品编辑',
      noCache: true
    },
    hidden: true
  }, {
    path: 'comment',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/comment'));
      });
    },
    name: 'goodsComment',
    meta: {
      perms: ['GET /admin/comment/list', 'POST /admin/comment/delete'],
      title: '商品评价',
      noCache: true
    }
  }, {
    path: 'category',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/category'));
      });
    },
    name: 'category',
    meta: {
      perms: ['GET /admin/category/list', 'POST /admin/category/create', 'GET /admin/category/read', 'POST /admin/category/update', 'POST /admin/category/delete'],
      title: '商品类目',
      // 新增商品类目（原商品管理下商品类目页面）
      noCache: true
    }
  }, {
    path: 'keyword',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/goods/keyword'));
      });
    },
    name: 'keyword',
    meta: {
      perms: ['GET /admin/keyword/list', 'POST /admin/keyword/create', 'GET /admin/keyword/read', 'POST /admin/keyword/update', 'POST /admin/keyword/delete'],
      title: '关键词管理',
      // 新增搜索推荐（原商场管理下关键词页面）
      noCache: true
    }
  }]
}, {
  path: '/promotion',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'promotionManage',
  meta: {
    title: '推广管理',
    icon: 'guide'
  },
  children: [{
    path: 'ad',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/ad'));
      });
    },
    name: 'ad',
    meta: {
      perms: ['GET /admin/ad/list', 'POST /admin/ad/create', 'GET /admin/ad/read', 'POST /admin/ad/update', 'POST /admin/ad/delete'],
      title: '广告管理',
      noCache: true
    }
  }, {
    path: 'dayRecommend',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/dayRecommend'));
      });
    },
    name: 'dayRecommend',
    meta: {
      perms: ['GET /admin/ad/list', 'POST /admin/ad/create', 'GET /admin/ad/read', 'POST /admin/ad/update', 'POST /admin/ad/delete'],
      title: '今日推荐',
      noCache: true
    }
  }, {
    path: 'dayOptimization',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/dayOptimization'));
      });
    },
    name: 'dayOptimization',
    meta: {
      perms: ['GET /admin/ad/list', 'POST /admin/ad/create', 'GET /admin/ad/read', 'POST /admin/ad/update', 'POST /admin/ad/delete'],
      title: '今日优选',
      noCache: true
    }
  }, {
    path: 'choiceness',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/choiceness'));
      });
    },
    name: 'choiceness',
    meta: {
      perms: ['GET /admin/ad/list', 'POST /admin/ad/create', 'GET /admin/ad/read', 'POST /admin/ad/update', 'POST /admin/ad/delete'],
      title: '精选',
      noCache: true
    }
  }, {
    path: 'coupon',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/coupon'));
      });
    },
    name: 'coupon',
    meta: {
      perms: ['GET /admin/coupon/list', 'POST /admin/coupon/create', 'POST /admin/coupon/update', 'POST /admin/coupon/delete'],
      title: '优惠券管理',
      noCache: true
    }
  }, {
    path: 'couponDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/couponDetail'));
      });
    },
    name: 'couponDetail',
    meta: {
      perms: ['GET /admin/coupon/list', 'GET /admin/coupon/listuser'],
      title: '优惠券详情',
      noCache: true
    },
    hidden: true
  }, {
    path: 'topic',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/topic'));
      });
    },
    name: 'topic',
    meta: {
      perms: ['GET /admin/topic/list', 'POST /admin/topic/create', 'GET /admin/topic/read', 'POST /admin/topic/update', 'POST /admin/topic/delete'],
      title: '活动管理',
      // 原专题管理
      noCache: true
    }
  }, {
    path: 'searchRecommend',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/searchRecommend'));
      });
    },
    name: 'searchRecommend',
    meta: {
      perms: ['GET /admin/ad/list', 'POST /admin/ad/create', 'GET /admin/ad/read', 'POST /admin/ad/update', 'POST /admin/ad/delete'],
      title: '搜索发现',
      // 新增搜索发现
      noCache: true
    },
    hidden: true
  }, {
    path: 'topic-create',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/topicCreate'));
      });
    },
    name: 'topicCreate',
    meta: {
      perms: ['POST /admin/topic/create'],
      title: '专题创建',
      noCache: true
    },
    hidden: true
  }, {
    path: 'topic-edit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/topicEdit'));
      });
    },
    name: 'topicEdit',
    meta: {
      perms: ['GET /admin/topic/read', 'POST /admin/topic/update'],
      title: '专题编辑',
      noCache: true
    },
    hidden: true
  }, {
    path: 'groupon-rule',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/grouponRule'));
      });
    },
    name: 'grouponRule',
    meta: {
      perms: ['GET /admin/groupon/list', 'POST /admin/groupon/create', 'POST /admin/groupon/update', 'POST /admin/groupon/delete'],
      title: '团购规则',
      noCache: true
    },
    hidden: true
  }, {
    path: 'groupon-activity',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/promotion/grouponActivity'));
      });
    },
    name: 'grouponActivity',
    meta: {
      perms: ['GET /admin/groupon/listRecord'],
      title: '团购活动',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/mall',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'mallManage',
  meta: {
    title: '销售管理',
    // 原商场管理
    icon: 'component'
  },
  children: [{
    path: 'region',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/region'));
      });
    },
    name: 'region',
    meta: {
      title: '行政区域',
      noCache: true
    },
    hidden: true
  }, {
    path: 'brand',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/brand'));
      });
    },
    name: 'brand',
    meta: {
      perms: ['GET /admin/brand/list', 'POST /admin/brand/create', 'GET /admin/brand/read', 'POST /admin/brand/update', 'POST /admin/brand/delete'],
      title: '品牌制造商',
      noCache: true
    },
    hidden: true
  }, {
    path: 'category',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/category'));
      });
    },
    name: 'category',
    meta: {
      perms: ['GET /admin/category/list', 'POST /admin/category/create', 'GET /admin/category/read', 'POST /admin/category/update', 'POST /admin/category/delete'],
      title: '商品类目',
      noCache: true
    },
    hidden: true
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/order'));
      });
    },
    name: 'order',
    meta: {
      perms: ['GET /admin/order/list', 'GET /admin/order/detail', 'POST /admin/order/ship', 'POST /admin/order/refund', 'POST /admin/order/delete', 'POST /admin/order/reply'],
      title: '订单管理',
      noCache: true
    }
  }, {
    path: 'aftersale',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/aftersale'));
      });
    },
    name: 'aftersale',
    meta: {
      perms: ['GET /admin/aftersale/list', 'GET /admin/aftersale/detail', 'POST /admin/order/receive', 'POST /admin/aftersale/complete', 'POST /admin/aftersale/reject'],
      title: '售后管理',
      noCache: true
    }
  }, {
    path: 'issue',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/issue'));
      });
    },
    name: 'issue',
    meta: {
      perms: ['GET /admin/issue/list', 'POST /admin/issue/create', 'GET /admin/issue/read', 'POST /admin/issue/update', 'POST /admin/issue/delete'],
      title: '通用问题',
      noCache: true
    },
    hidden: true
  }, {
    path: 'keyword',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/mall/keyword'));
      });
    },
    name: 'keyword',
    meta: {
      perms: ['GET /admin/keyword/list', 'POST /admin/keyword/create', 'GET /admin/keyword/read', 'POST /admin/keyword/update', 'POST /admin/keyword/delete'],
      title: '关键词',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/sys',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'sysManage',
  meta: {
    title: '系统管理',
    icon: 'example'
  },
  hidden: true,
  children: [{
    path: 'admin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/admin'));
      });
    },
    name: 'admin',
    meta: {
      perms: ['GET /admin/admin/list', 'POST /admin/admin/create', 'POST /admin/admin/update', 'POST /admin/admin/delete'],
      title: '管理员',
      noCache: true
    }
  }, {
    path: 'notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/notice'));
      });
    },
    name: 'sysNotice',
    meta: {
      perms: ['GET /admin/notice/list', 'POST /admin/notice/create', 'POST /admin/notice/update', 'POST /admin/notice/delete'],
      title: '通知管理',
      noCache: true
    }
  }, {
    path: 'log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/log'));
      });
    },
    name: 'log',
    meta: {
      perms: ['GET /admin/log/list'],
      title: '操作日志',
      noCache: true
    }
  }, {
    path: 'role',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/role'));
      });
    },
    name: 'role',
    meta: {
      perms: ['GET /admin/role/list', 'POST /admin/role/create', 'POST /admin/role/update', 'POST /admin/role/delete', 'GET /admin/role/permissions', 'POST /admin/role/permissions'],
      title: '角色管理',
      noCache: true
    }
  }, {
    path: 'os',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sys/os'));
      });
    },
    name: 'os',
    meta: {
      perms: ['GET /admin/storage/list', 'POST /admin/storage/create', 'POST /admin/storage/update', 'POST /admin/storage/delete'],
      title: '对象存储',
      noCache: true
    }
  }]
}, {
  path: '/config',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'configManage',
  meta: {
    title: '配置管理',
    icon: 'chart'
  },
  hidden: true,
  children: [{
    path: 'mall',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/mall'));
      });
    },
    name: 'configMall',
    meta: {
      perms: ['GET /admin/config/mall', 'POST /admin/config/mall'],
      title: '商场配置',
      noCache: true
    }
  }, {
    path: 'express',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/express'));
      });
    },
    name: 'configExpress',
    meta: {
      perms: ['GET /admin/config/express', 'POST /admin/config/express'],
      title: '运费配置',
      noCache: true
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/order'));
      });
    },
    name: 'configOrder',
    meta: {
      perms: ['GET /admin/config/order', 'POST /admin/config/order'],
      title: '订单配置',
      noCache: true
    }
  }, {
    path: 'wx',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/config/wx'));
      });
    },
    name: 'configWx',
    meta: {
      perms: ['GET /admin/config/wx', 'POST /admin/config/wx'],
      title: '小程序配置',
      noCache: true
    }
  }]
}, {
  path: '/stat',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'statManage',
  meta: {
    title: '统计报表',
    icon: 'table'
  },
  hidden: true,
  children: [{
    path: 'user',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stat/user'));
      });
    },
    name: 'statUser',
    meta: {
      perms: ['GET /admin/stat/user'],
      title: '用户统计',
      noCache: true
    }
  }, {
    path: 'order',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stat/order'));
      });
    },
    name: 'statOrder',
    meta: {
      perms: ['GET /admin/stat/order'],
      title: '订单统计',
      noCache: true
    }
  }, {
    path: 'goods',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/stat/goods'));
      });
    },
    name: 'statGoods',
    meta: {
      perms: ['GET /admin/stat/goods'],
      title: '商品统计',
      noCache: true
    }
  }]
}, {
  path: 'external-link',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'externalLink',
  meta: {
    title: '外链',
    icon: 'link'
  },
  hidden: true,
  children: [{
    path: 'https://cloud.tencent.com/product/cos',
    meta: {
      title: '腾讯云存储',
      icon: 'link'
    }
  }, {
    path: 'https://cloud.tencent.com/product/sms',
    meta: {
      title: '腾讯云短信',
      icon: 'link'
    }
  }, {
    path: 'https://pay.weixin.qq.com/index.php/core/home/login',
    meta: {
      title: '微信支付',
      icon: 'link'
    }
  }, {
    path: 'https://mpkf.weixin.qq.com/',
    meta: {
      title: '小程序客服',
      icon: 'link'
    }
  }, {
    path: 'https://www.alibabacloud.com/zh/product/oss',
    meta: {
      title: '阿里云存储',
      icon: 'link'
    }
  }, {
    path: 'https://www.qiniu.com/products/kodo',
    meta: {
      title: '七牛云存储',
      icon: 'link'
    }
  }, {
    path: 'http://www.kdniao.com/api-track',
    meta: {
      title: '快递鸟',
      icon: 'link'
    }
  }]
}, {
  path: '/profile',
  component: _Layout.default,
  redirect: 'noredirect',
  alwaysShow: true,
  children: [{
    path: 'password',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/profile/password'));
      });
    },
    name: 'password',
    meta: {
      title: '修改密码',
      noCache: true
    }
  }, {
    path: 'notice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/profile/notice'));
      });
    },
    name: 'notice',
    meta: {
      title: '通知中心',
      noCache: true
    }
  }],
  hidden: true
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRouterMap = asyncRouterMap;