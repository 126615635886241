var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.dataForm,
            "status-icon": "",
            "label-width": "300px"
          }
        },
        [
          _c(
            "el-tabs",
            { attrs: { "tab-position": "left" } },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "首页配置" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "新品首发栏目商品显示数量",
                        prop: "litemall_wx_index_new"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.dataForm.litemall_wx_index_new,
                          callback: function($$v) {
                            _vm.$set(_vm.dataForm, "litemall_wx_index_new", $$v)
                          },
                          expression: "dataForm.litemall_wx_index_new"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "人气推荐栏目商品显示数量",
                        prop: "litemall_wx_index_hot"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.dataForm.litemall_wx_index_hot,
                          callback: function($$v) {
                            _vm.$set(_vm.dataForm, "litemall_wx_index_hot", $$v)
                          },
                          expression: "dataForm.litemall_wx_index_hot"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "品牌制造商直供栏目品牌商显示数量",
                        prop: "litemall_wx_index_brand"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.dataForm.litemall_wx_index_brand,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.dataForm,
                              "litemall_wx_index_brand",
                              $$v
                            )
                          },
                          expression: "dataForm.litemall_wx_index_brand"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "专题精选栏目显示数量",
                        prop: "litemall_wx_index_topic"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.dataForm.litemall_wx_index_topic,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.dataForm,
                              "litemall_wx_index_topic",
                              $$v
                            )
                          },
                          expression: "dataForm.litemall_wx_index_topic"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类栏目显示数量",
                        prop: "litemall_wx_catlog_list"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.dataForm.litemall_wx_catlog_list,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.dataForm,
                              "litemall_wx_catlog_list",
                              $$v
                            )
                          },
                          expression: "dataForm.litemall_wx_catlog_list"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类栏目商品显示数量",
                        prop: "litemall_wx_catlog_goods"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.dataForm.litemall_wx_catlog_goods,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.dataForm,
                              "litemall_wx_catlog_goods",
                              $$v
                            )
                          },
                          expression: "dataForm.litemall_wx_catlog_goods"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "其他配置" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商品分享功能",
                        prop: "litemall_wx_share"
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.dataForm.litemall_wx_share,
                          callback: function($$v) {
                            _vm.$set(_vm.dataForm, "litemall_wx_share", $$v)
                          },
                          expression: "dataForm.litemall_wx_share"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }