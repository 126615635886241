var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "请输入id" },
            model: {
              value: _vm.listQuery.uid,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "uid", $$v)
              },
              expression: "listQuery.uid"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "请输入用户昵称" },
            model: {
              value: _vm.listQuery.name,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "name", $$v)
              },
              expression: "listQuery.name"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", placeholder: "请输入手机号" },
            model: {
              value: _vm.listQuery.mobile,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "mobile", $$v)
              },
              expression: "listQuery.mobile"
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.handleFilter(0)
                }
              }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleRecharge }
            },
            [_vm._v("充值")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v("添加员工")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100px",
              label: "用户ID",
              prop: "uid",
              sortable: ""
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "昵称", prop: "name" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "createTimeCopy"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "手机号码", prop: "mobile" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "员工分类", prop: "employeeType" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "余额", prop: "money" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "最后充值金额",
              prop: "lastChargeMoney"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "最近充值时间",
              prop: "lastChargeTime"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "200",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus] + "员工",
            visible: _vm.dialogEmployeeVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEmployeeVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入id" },
                model: {
                  value: _vm.userListQuery.uid,
                  callback: function($$v) {
                    _vm.$set(_vm.userListQuery, "uid", $$v)
                  },
                  expression: "userListQuery.uid"
                }
              }),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入用户昵称" },
                model: {
                  value: _vm.userListQuery.name,
                  callback: function($$v) {
                    _vm.$set(_vm.userListQuery, "name", $$v)
                  },
                  expression: "userListQuery.name"
                }
              }),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入手机号" },
                model: {
                  value: _vm.userListQuery.mobile,
                  callback: function($$v) {
                    _vm.$set(_vm.userListQuery, "mobile", $$v)
                  },
                  expression: "userListQuery.mobile"
                }
              }),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleFilter(1)
                    }
                  }
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-check" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleCheckedAll(1)
                    }
                  }
                },
                [_vm._v("勾选全部")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              key: Math.random(),
              attrs: {
                data: _vm.userList,
                "element-loading-text": "正在查询中。。。",
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100px",
                  label: "用户ID",
                  prop: "uid",
                  sortable: ""
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "昵称", prop: "name" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  prop: "createTimeCopy"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "手机号", prop: "mobile" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "员工分类",
                  prop: "employeeType"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.employeeType == 1
                                ? "中标优选"
                                : "普通员工"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "员工标记" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm.changeUserDom
                          ? [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { checked: scope.row.checked },
                                  on: {
                                    change: function($event) {
                                      return _vm.checkEmplpyeeChange(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("员工")]
                              )
                            ]
                          : undefined
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              [
                _vm._v(" 员工类型 "),
                _c(
                  "el-select",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.typeCode,
                      callback: function($$v) {
                        _vm.typeCode = $$v
                      },
                      expression: "typeCode"
                    }
                  },
                  _vm._l(_vm.typeList, function(item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                )
              ],
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmAdd }
                },
                [_vm._v("确认添加")]
              )
            ],
            2
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.userTotal > 0,
                expression: "userTotal>0"
              }
            ],
            attrs: {
              total: _vm.userTotal,
              page: _vm.userListQuery.page,
              limit: _vm.userListQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.userListQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.userListQuery, "limit", $event)
              },
              pagination: _vm.getUserList
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "充值", visible: _vm.dialogRechargeVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogRechargeVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入id" },
                model: {
                  value: _vm.chargeListQuery.uid,
                  callback: function($$v) {
                    _vm.$set(_vm.chargeListQuery, "uid", $$v)
                  },
                  expression: "chargeListQuery.uid"
                }
              }),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入用户昵称" },
                model: {
                  value: _vm.chargeListQuery.name,
                  callback: function($$v) {
                    _vm.$set(_vm.chargeListQuery, "name", $$v)
                  },
                  expression: "chargeListQuery.name"
                }
              }),
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { clearable: "", placeholder: "请输入手机号" },
                model: {
                  value: _vm.chargeListQuery.mobile,
                  callback: function($$v) {
                    _vm.$set(_vm.chargeListQuery, "mobile", $$v)
                  },
                  expression: "chargeListQuery.mobile"
                }
              }),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleFilter(2)
                    }
                  }
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-check" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleCheckedAll(2)
                    }
                  }
                },
                [_vm._v("勾选全部")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.chargeListLoading,
                  expression: "chargeListLoading"
                }
              ],
              key: Math.random(),
              attrs: {
                data: _vm.chargeList,
                "element-loading-text": "正在查询中。。。",
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100px",
                  label: "用户ID",
                  prop: "uid",
                  sortable: ""
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "昵称", prop: "name" }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "手机号", prop: "mobile" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "员工分类",
                  prop: "employeeType"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.employeeType == 1
                                ? "中标优选"
                                : "普通员工"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "充值标记" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm.changeChargeDom
                          ? [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { checked: scope.row.checked },
                                  on: {
                                    change: function($event) {
                                      return _vm.checkChargeChange(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("员工")]
                              )
                            ]
                          : undefined
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "20px",
                display: "flex",
                "justify-content": "space-between"
              }
            },
            [
              _c(
                "div",
                [
                  _c("span", [_vm._v("充值金额")]),
                  _c("el-input", {
                    staticStyle: { width: "200px", "margin-left": "10px" },
                    model: {
                      value: _vm.chargeMoney,
                      callback: function($$v) {
                        _vm.chargeMoney = $$v
                      },
                      expression: "chargeMoney"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmCharge }
                },
                [_vm._v("充值")]
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticStyle: { "margin-top": "200px" },
              attrs: {
                title: "提示",
                visible: _vm.chargeConfirmDialogVisible,
                width: "30%",
                center: "",
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.chargeConfirmDialogVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" }
                },
                [
                  _vm._v(
                    "为" +
                      _vm._s(_vm.chargeNumber) +
                      "个员工充值" +
                      _vm._s(_vm.chargeMoney) +
                      "元"
                  )
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.chargeConfirmDialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.getConfirmCharge($event)
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ]
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.chargeTotal > 0,
                expression: "chargeTotal>0"
              }
            ],
            attrs: {
              total: _vm.chargeTotal,
              page: _vm.chargeListQuery.page,
              limit: _vm.chargeListQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.chargeListQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.chargeListQuery, "limit", $event)
              },
              pagination: _vm.getCharegeList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }