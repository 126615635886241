var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.dataForm,
            "status-icon": "",
            "label-width": "300px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: "用户下单后超时", prop: "litemall_order_unpaid" }
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-width",
                  model: {
                    value: _vm.dataForm.litemall_order_unpaid,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "litemall_order_unpaid", $$v)
                    },
                    expression: "dataForm.litemall_order_unpaid"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("分钟")])],
                2
              ),
              _c("span", { staticClass: "info" }, [
                _vm._v("用户未付款，则订单自动取消")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "订单发货后超期",
                prop: "litemall_order_unconfirm"
              }
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-width",
                  model: {
                    value: _vm.dataForm.litemall_order_unconfirm,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "litemall_order_unconfirm", $$v)
                    },
                    expression: "dataForm.litemall_order_unconfirm"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v(" 天")])],
                2
              ),
              _c("span", { staticClass: "info" }, [
                _vm._v("未确认收货，则订单自动确认收货")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "确认收货后超期", prop: "litemall_order_comment" }
            },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-width",
                  model: {
                    value: _vm.dataForm.litemall_order_comment,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "litemall_order_comment", $$v)
                    },
                    expression: "dataForm.litemall_order_comment"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("天")])],
                2
              ),
              _c("span", { staticClass: "info" }, [
                _vm._v("未评价商品，则取消评价资格")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }