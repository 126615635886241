var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品ID" },
            model: {
              value: _vm.listQuery.goodsId,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "goodsId", $$v)
              },
              expression: "listQuery.goodsId"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品编号" },
            model: {
              value: _vm.listQuery.goodsSn,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "goodsSn", $$v)
              },
              expression: "listQuery.goodsSn"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入商品名称" },
            model: {
              value: _vm.listQuery.name,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "name", $$v)
              },
              expression: "listQuery.name"
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v("添加商品")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "table-expand",
                        attrs: { "label-position": "left" }
                      },
                      [
                        _c("el-form-item", { attrs: { label: "商品编号" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.goodsSn))])
                        ]),
                        _c(
                          "el-form-item",
                          { attrs: { label: "宣传画廊" } },
                          _vm._l(props.row.gallery, function(pic) {
                            return _c("img", {
                              key: pic,
                              staticClass: "gallery",
                              attrs: { src: pic }
                            })
                          }),
                          0
                        ),
                        _c("el-form-item", { attrs: { label: "商品介绍" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.brief))])
                        ]),
                        _c("el-form-item", { attrs: { label: "商品单位" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.unit))])
                        ]),
                        _c("el-form-item", { attrs: { label: "关键字" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.keywords))])
                        ]),
                        _c("el-form-item", { attrs: { label: "类目ID" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.categoryId))])
                        ]),
                        _c("el-form-item", { attrs: { label: "品牌商ID" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.brandId))])
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品ID", prop: "id" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "名称",
              prop: "name"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "商品编号",
              prop: "goodsSn"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", property: "iconUrl", label: "图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("img", { attrs: { src: scope.row.picUrl, width: "40" } })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "标价", prop: "counterPrice" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.counterPrice) + "元")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "售价", prop: "retailPrice" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.retailPrice) + "元")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "会员价", prop: "vipPrice" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.vipPrice) + "元")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "是否新品", prop: "isNew" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: { type: scope.row.isNew ? "success" : "error" }
                      },
                      [_vm._v(_vm._s(scope.row.isNew ? "新品" : "非新品"))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "是否热品", prop: "isHot" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: { type: scope.row.isHot ? "success" : "error" }
                      },
                      [_vm._v(_vm._s(scope.row.isHot ? "热品" : "非热品"))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "是否在售", prop: "isOnSale" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: scope.row.isOnSale ? "success" : "error"
                        }
                      },
                      [_vm._v(_vm._s(scope.row.isOnSale ? "在售" : "未售"))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "200",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-tooltip",
        { attrs: { placement: "top", content: "返回顶部" } },
        [_c("back-to-top", { attrs: { "visibility-height": 100 } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }