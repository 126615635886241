"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _stat = require("@/api/stat");

var _line = _interopRequireDefault(require("v-charts/lib/line"));

//
//
//
//
//
//
var _default = {
  components: {
    VeLine: _line.default
  },
  data: function data() {
    return {
      chartData: {},
      chartSettings: {},
      chartExtend: {}
    };
  },
  created: function created() {
    var _this = this;

    (0, _stat.statOrder)().then(function (response) {
      _this.chartData = response.data.d;
      _this.chartSettings = {
        labelMap: {
          'orders': '订单量',
          'customers': '下单用户',
          'amount': '订单总额',
          'pcr': '客单价'
        }
      };
      _this.chartExtend = {
        xAxis: {
          boundaryGap: true
        }
      };
    });
  }
};
exports.default = _default;