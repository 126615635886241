"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

require("core-js/modules/web.dom.iterable");

var _aftersale = require("@/api/aftersale");

var _BackToTop = _interopRequireDefault(require("@/components/BackToTop"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _lodash = _interopRequireDefault(require("lodash"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = {
  name: 'Aftersale',
  components: {
    BackToTop: _BackToTop.default,
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      tab: 'all',
      listQuery: {
        page: 1,
        limit: 20,
        aftersaleSn: undefined,
        orderId: undefined,
        status: '',
        sort: 'add_time',
        order: 'desc'
      },
      typeTag: ['', 'success', 'warning'],
      typeDesc: ['未收货退款', '不退货退款', '退货退款'],
      multipleSelection: [],
      contentDetail: '',
      contentDialogVisible: false,
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _aftersale.listAftersale)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this.list = response.data.d.list;

          if (response.data.d.totalPage) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClick: function handleClick() {
      if (this.tab === 'all') {
        this.listQuery.status = '';
      } else if (this.tab === 'uncheck') {
        this.listQuery.status = '1';
      } else if (this.tab === 'unrefund') {
        this.listQuery.status = '2';
      }

      this.getList();
    },
    handleRecept: function handleRecept(row) {
      var _this2 = this;

      (0, _aftersale.receptAftersale)(row).then(function (response) {
        _this2.$notify.success({
          title: '成功',
          message: '审核通过操作成功'
        });

        _this2.getList();
      }).catch(function (response) {
        _this2.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleBatchRecept: function handleBatchRecept() {
      var _this3 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择至少一条记录');
        return;
      }

      var ids = [];

      _lodash.default.forEach(this.multipleSelection, function (item) {
        ids.push(item.id);
      });

      (0, _aftersale.batchReceptAftersale)({
        ids: ids
      }).then(function (response) {
        _this3.$notify.success({
          title: '成功',
          message: '批量通过操作成功'
        });

        _this3.getList();
      }).catch(function (response) {
        _this3.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleReject: function handleReject(row) {
      var _this4 = this;

      (0, _aftersale.rejectAftersale)(row).then(function (response) {
        _this4.$notify.success({
          title: '成功',
          message: '审核拒绝操作成功'
        });

        _this4.getList();
      }).catch(function (response) {
        _this4.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleBatchReject: function handleBatchReject() {
      var _this5 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择至少一条记录');
        return;
      }

      var ids = [];

      _lodash.default.forEach(this.multipleSelection, function (item) {
        ids.push(item.id);
      });

      (0, _aftersale.batchRejectAftersale)({
        ids: ids
      }).then(function (response) {
        _this5.$notify.success({
          title: '成功',
          message: '批量拒绝操作成功'
        });

        _this5.getList();
      }).catch(function (response) {
        _this5.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleRefund: function handleRefund(row) {
      var _this6 = this;

      (0, _aftersale.refundAftersale)(row).then(function (response) {
        _this6.$notify.success({
          title: '成功',
          message: '退款操作成功'
        });

        _this6.getList();
      }).catch(function (response) {
        _this6.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleDownload: function handleDownload() {
      var _this7 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['售后编号', '订单ID', '用户ID', '售后类型', '退款原因', '退款价格', '申请时间'];
        var filterVal = ['aftersaleSn', 'orderId', 'userId', 'type', 'reason', 'amount', 'addTime'];
        excel.export_json_to_excel2(tHeader, _this7.list, filterVal, '售后信息');
        _this7.downloadLoading = false;
      });
    }
  }
};
exports.default = _default;