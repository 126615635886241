"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _category = require("@/api/category");

var _storage = require("@/api/storage");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Category',
  data: function data() {
    return {
      uploadPath: _storage.uploadPath,
      list: [],
      listLoading: true,
      catL1: {},
      dataForm: {
        id: undefined,
        name: '',
        keywords: '',
        level: 'L2',
        pid: 0,
        desc: '',
        iconUrl: '',
        picUrl: ''
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{
          required: true,
          message: '类目名不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    headers: function headers() {
      return {
        'x-auth-token': (0, _auth.getToken)()
      };
    }
  },
  created: function created() {
    this.getList();
    this.getCatL1();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _category.listCategory)().then(function (response) {
        _this.list = response.data.d.list;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.listLoading = false;
      });
    },
    getCatL1: function getCatL1() {
      var _this2 = this;

      (0, _category.listCatL1)().then(function (response) {
        _this2.catL1 = response.data.d.list;
      });
    },
    resetForm: function resetForm() {
      this.dataForm = {
        id: undefined,
        name: '',
        keywords: '',
        level: 'L2',
        pid: 0,
        desc: '',
        iconUrl: '',
        picUrl: ''
      };
    },
    onLevelChange: function onLevelChange(value) {
      if (value === 'L1') {
        this.dataForm.pid = 0;
      }
    },
    handleCreate: function handleCreate() {
      var _this3 = this;

      this.resetForm();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    uploadIconUrl: function uploadIconUrl(response) {
      this.dataForm.iconUrl = response.d.url;
    },
    uploadPicUrl: function uploadPicUrl(response) {
      this.dataForm.picUrl = response.d.url;
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _category.createCategory)(_this4.dataForm).then(function (response) {
            _this4.getList(); // 更新L1目录


            _this4.getCatL1();

            _this4.dialogFormVisible = false;

            _this4.$notify.success({
              title: '成功',
              message: '创建成功'
            });
          }).catch(function (response) {
            _this4.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;

      this.dataForm = Object.assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _category.updateCategory)(_this6.dataForm).then(function () {
            _this6.getList(); // 更新L1目录


            _this6.getCatL1();

            _this6.dialogFormVisible = false;

            _this6.$notify.success({
              title: '成功',
              message: '更新成功'
            });
          }).catch(function (response) {
            _this6.$notify.error({
              title: '失败',
              message: response.data.m
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this7 = this;

      (0, _category.deleteCategory)(row).then(function (response) {
        _this7.getList(); // 更新L1目录


        _this7.getCatL1();

        _this7.$notify.success({
          title: '成功',
          message: '删除成功'
        });
      }).catch(function (response) {
        _this7.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    }
  }
};
exports.default = _default;