"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  data: function data() {
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('管理员密码长度应大于6'));
      } else {
        callback();
      }
    };

    return {
      loginForm: {
        // username: '15201008961',
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          message: '管理员账户不允许为空',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '管理员密码不允许为空',
          trigger: 'blur'
        }, {
          validator: validatePassword,
          trigger: 'blur'
        }]
      },
      passwordType: 'password',
      loading: false
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {// window.addEventListener('hashchange', this.afterQRScan)
  },
  destroyed: function destroyed() {// window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    handleLogin: function handleLogin() {
      var _this = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid && !_this.loading) {
          _this.loading = true;

          _this.$store.dispatch('LoginByUsername', _this.loginForm).then(function () {
            _this.loading = false;

            _this.$router.push('/user/usercommon');
          }).catch(function (response) {
            _this.$notify.error({
              title: '失败',
              message: response.message
            });

            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;