"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _profile = require("@/api/profile");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _lodash = _interopRequireDefault(require("lodash"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Secondary package based on el-pagination
var _default = {
  name: 'AdminNotice',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        title: '',
        type: 'unread',
        sort: 'add_time',
        order: 'desc'
      },
      multipleSelection: [],
      notice: {
        title: '',
        source: '',
        content: '',
        addTime: ''
      },
      noticeVisible: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _profile.listNotice)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this.list = response.data.d.list;

          if (response.data.d.totalPage) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;

      (0, _profile.rmNotice)(row).then(function (response) {
        _this2.$notify.success({
          title: '成功',
          message: '删除通知成功'
        });

        var index = _this2.list.indexOf(row);

        _this2.list.splice(index, 1);
      }).catch(function (response) {
        _this2.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleBatchDelete: function handleBatchDelete() {
      var _this3 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择至少一条记录');
        return;
      }

      var ids = [];

      _lodash.default.forEach(this.multipleSelection, function (item) {
        ids.push(item.id);
      });

      (0, _profile.brmNotice)({
        ids: ids
      }).then(function (response) {
        _this3.$notify.success({
          title: '成功',
          message: '批量删除通知成功'
        });

        _this3.getList();
      }).catch(function (response) {
        _this3.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    },
    handleRead: function handleRead(row) {
      var _this4 = this;

      (0, _profile.catNotice)(row).then(function (response) {
        _this4.notice = response.data.d;
        _this4.noticeVisible = true;
      });
    },
    afterRead: function afterRead() {
      this.noticeVisible = false;
      this.getList();
    },
    handleBatchRead: function handleBatchRead() {
      var _this5 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择至少一条记录');
        return;
      }

      var ids = [];

      _lodash.default.forEach(this.multipleSelection, function (item) {
        ids.push(item.id);
      });

      (0, _profile.bcatNotice)({
        ids: ids
      }).then(function (response) {
        _this5.$notify.success({
          title: '成功',
          message: '批量设置通知已读成功'
        });

        _this5.getList();
      }).catch(function (response) {
        _this5.$notify.error({
          title: '失败',
          message: response.data.m
        });
      });
    }
  }
};
exports.default = _default;