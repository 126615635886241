"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loginByUsername = loginByUsername;
exports.logout = logout;
exports.getUserInfo = getUserInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

function loginByUsername(username, password) {
  var data = {
    username: username,
    password: password
  };
  return (0, _request.default)({
    url: '/auth/login',
    method: 'post',
    data: data
  });
}

function logout() {
  return (0, _request.default)({
    url: '/auth/logout',
    method: 'post'
  });
}

function getUserInfo(token) {
  return (0, _request.default)({
    url: '/auth/info',
    method: 'get',
    params: {
      token: token
    }
  });
}