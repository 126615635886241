var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入用户ID" },
            model: {
              value: _vm.listQuery.userId,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "userId", $$v)
              },
              expression: "listQuery.userId"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "160px" },
            attrs: { clearable: "", placeholder: "请输入订单编号" },
            model: {
              value: _vm.listQuery.orderSn,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "orderSn", $$v)
              },
              expression: "listQuery.orderSn"
            }
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "datetimerange",
              "value-format": "yyyy-MM-dd HH:mm:ss",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              "picker-options": _vm.pickerOptions
            },
            model: {
              value: _vm.listQuery.timeArray,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "timeArray", $$v)
              },
              expression: "listQuery.timeArray"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { multiple: "", placeholder: "请选择订单状态" },
              model: {
                value: _vm.listQuery.orderStatusArray,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "orderStatusArray", $$v)
                },
                expression: "listQuery.orderStatusArray"
              }
            },
            _vm._l(_vm.statusMap, function(key, value) {
              return _c("el-option", {
                key: key,
                attrs: { label: key, value: value }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["GET /admin/order/list"],
                  expression: "['GET /admin/order/list']"
                }
              ],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v("查找")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100",
              label: "订单编号",
              prop: "orderSn"
            }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户ID", prop: "userId" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单状态", prop: "orderStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(
                          _vm._f("orderStatusFilter")(scope.row.orderStatus)
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单金额", prop: "orderPrice" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "支付金额", prop: "actualPrice" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "支付时间", prop: "payTime" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "物流单号", prop: "shipSn" }
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "物流渠道", prop: "shipChannel" }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "250",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["GET /admin/order/detail"],
                            expression: "['GET /admin/order/detail']"
                          }
                        ],
                        staticClass: "op-btn",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["POST /admin/order/delete"],
                            expression: "['POST /admin/order/delete']"
                          }
                        ],
                        staticClass: "op-btn",
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    ),
                    scope.row.orderStatus == 201 || scope.row.orderStatus == 301
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/order/ship"],
                                expression: "['POST /admin/order/ship']"
                              }
                            ],
                            staticClass: "op-btn",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleShip(scope.row)
                              }
                            }
                          },
                          [_vm._v("修改")]
                        )
                      : _vm._e(),
                    scope.row.orderStatus == 201
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/order/ship"],
                                expression: "['POST /admin/order/ship']"
                              }
                            ],
                            staticClass: "op-btn",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleShip(scope.row)
                              }
                            }
                          },
                          [_vm._v("发货")]
                        )
                      : _vm._e(),
                    scope.row.orderStatus == 202 || scope.row.orderStatus == 204
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["POST /admin/order/refund"],
                                expression: "['POST /admin/order/refund']"
                              }
                            ],
                            staticClass: "op-btn",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.handleRefund(scope.row)
                              }
                            }
                          },
                          [_vm._v("退款")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.orderDialogVisible,
            title: "订单详情",
            width: "800"
          },
          on: {
            "update:visible": function($event) {
              _vm.orderDialogVisible = $event
            }
          }
        },
        [
          _c(
            "section",
            { ref: "print" },
            [
              _c(
                "el-form",
                { attrs: { data: _vm.orderDetail, "label-position": "left" } },
                [
                  _c("el-form-item", { attrs: { label: "订单编号" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.orderDetail.order.orderSn))])
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态" } },
                    [
                      _c("el-tag", [
                        _vm._v(
                          _vm._s(
                            _vm._f("orderStatusFilter")(
                              _vm.orderDetail.order.orderStatus
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "订单用户" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.orderDetail.user && _vm.orderDetail.user.nickname
                            ? _vm.orderDetail.user.nickname
                            : ""
                        )
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "用户留言" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.orderDetail.order.message))])
                  ]),
                  _c("el-form-item", { attrs: { label: "收货信息" } }, [
                    _c("span", [
                      _vm._v(
                        "（收货人）" + _vm._s(_vm.orderDetail.order.consignee)
                      )
                    ]),
                    _c("span", [
                      _vm._v(
                        "（手机号）" + _vm._s(_vm.orderDetail.order.mobile)
                      )
                    ]),
                    _c("span", [
                      _vm._v("（地址）" + _vm._s(_vm.orderDetail.order.address))
                    ])
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品信息" } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.orderDetail.orderGoods,
                            border: "",
                            fit: "",
                            "highlight-current-row": ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "商品名称",
                              prop: "goodsName"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "商品编号",
                              prop: "goodsSn"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "货品规格",
                              prop: "specifications"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "货品价格",
                              prop: "price"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "货品数量",
                              prop: "number"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "货品图片",
                              prop: "picUrl"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("img", {
                                      attrs: {
                                        src: scope.row.picUrl,
                                        width: "40"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "费用信息" } }, [
                    _c("span", [
                      _vm._v(
                        " (实际费用)" +
                          _vm._s(_vm.orderDetail.order.actualPrice) +
                          "元 = (商品总价)" +
                          _vm._s(_vm.orderDetail.order.goodsPrice) +
                          "元 + (快递费用)" +
                          _vm._s(_vm.orderDetail.order.freightPrice) +
                          "元 - (优惠减免)" +
                          _vm._s(_vm.orderDetail.order.couponPrice) +
                          "元 - (积分减免)" +
                          _vm._s(_vm.orderDetail.order.integralPrice) +
                          "元 "
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "支付信息" } }, [
                    _c("span", [_vm._v("（支付渠道）微信支付")]),
                    _c("span", [
                      _vm._v(
                        "（支付时间）" + _vm._s(_vm.orderDetail.order.payTime)
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "快递信息" } }, [
                    _c("span", [
                      _vm._v(
                        "（快递公司）" +
                          _vm._s(_vm.orderDetail.order.shipChannel)
                      )
                    ]),
                    _c("span", [
                      _vm._v(
                        "（快递单号）" + _vm._s(_vm.orderDetail.order.shipSn)
                      )
                    ]),
                    _c("span", [
                      _vm._v(
                        "（发货时间）" + _vm._s(_vm.orderDetail.order.shipTime)
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "退款信息" } }, [
                    _c("span", [
                      _vm._v(
                        "（退款金额）" +
                          _vm._s(_vm.orderDetail.order.refundAmount) +
                          "元"
                      )
                    ]),
                    _c("span", [
                      _vm._v(
                        "（退款类型）" +
                          _vm._s(_vm.orderDetail.order.refundType)
                      )
                    ]),
                    _c("span", [
                      _vm._v(
                        "（退款备注）" +
                          _vm._s(_vm.orderDetail.order.refundContent)
                      )
                    ]),
                    _c("span", [
                      _vm._v(
                        "（退款时间）" +
                          _vm._s(_vm.orderDetail.order.refundTime)
                      )
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "收货信息" } }, [
                    _c("span", [
                      _vm._v(
                        "（确认收货时间）" +
                          _vm._s(_vm.orderDetail.order.confirmTime)
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.orderDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.shipDialogVisible, title: "发货" },
          on: {
            "update:visible": function($event) {
              _vm.shipDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "shipForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.shipForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司", prop: "shipChannel" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.shipForm.shipChannel,
                        callback: function($$v) {
                          _vm.$set(_vm.shipForm, "shipChannel", $$v)
                        },
                        expression: "shipForm.shipChannel"
                      }
                    },
                    _vm._l(_vm.channels, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "快递编号", prop: "shipSn" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.shipForm.shipSn,
                      callback: function($$v) {
                        _vm.$set(_vm.shipForm, "shipSn", $$v)
                      },
                      expression: "shipForm.shipSn"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.shipDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmShip } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.modifyShipDialogVisible,
            title: "修改快递单号"
          },
          on: {
            "update:visible": function($event) {
              _vm.modifyShipDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "modifyShipForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.shipForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司", prop: "shipChannel" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.shipForm.shipChannel,
                        callback: function($$v) {
                          _vm.$set(_vm.shipForm, "shipChannel", $$v)
                        },
                        expression: "shipForm.shipChannel"
                      }
                    },
                    _vm._l(_vm.channels, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "快递编号", prop: "shipSn" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.shipForm.shipSn,
                      callback: function($$v) {
                        _vm.$set(_vm.shipForm, "shipSn", $$v)
                      },
                      expression: "shipForm.shipSn"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.shipDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmModifyShip }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.refundDialogVisible, title: "退款" },
          on: {
            "update:visible": function($event) {
              _vm.refundDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "refundForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.refundForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "退款金额", prop: "refundMoney" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.refundForm.refundMoney,
                      callback: function($$v) {
                        _vm.$set(_vm.refundForm, "refundMoney", $$v)
                      },
                      expression: "refundForm.refundMoney"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.refundDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmRefund }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }