"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listOrder = listOrder;
exports.detailOrder = detailOrder;
exports.shipOrder = shipOrder;
exports.refundOrder = refundOrder;
exports.deleteOrder = deleteOrder;
exports.replyComment = replyComment;
exports.listChannel = listChannel;

var _request = _interopRequireDefault(require("@/utils/request"));

var _qs = _interopRequireDefault(require("qs"));

function listOrder(query) {
  return (0, _request.default)({
    url: '/order/list',
    method: 'get',
    params: query,
    paramsSerializer: function paramsSerializer(params) {
      return _qs.default.stringify(params, {
        arrayFormat: 'repeat'
      });
    }
  });
}

function detailOrder(id) {
  return (0, _request.default)({
    url: '/order/detail',
    method: 'get',
    params: {
      id: id
    }
  });
}

function shipOrder(data) {
  return (0, _request.default)({
    url: '/order/ship',
    method: 'post',
    data: data
  });
}

function refundOrder(data) {
  return (0, _request.default)({
    url: '/order/refund',
    method: 'post',
    data: data
  });
}

function deleteOrder(data) {
  return (0, _request.default)({
    url: '/order/delete',
    method: 'post',
    data: data
  });
}

function replyComment(data) {
  return (0, _request.default)({
    url: '/order/reply',
    method: 'post',
    data: data
  });
}

function listChannel(id) {
  return (0, _request.default)({
    url: '/order/channel',
    method: 'get'
  });
}