var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.dataForm,
            "status-icon": "",
            "label-width": "300px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商场名称", prop: "litemall_mall_name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.dataForm.litemall_mall_name,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "litemall_mall_name", $$v)
                  },
                  expression: "dataForm.litemall_mall_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商场地址", prop: "litemall_mall_address" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.dataForm.litemall_mall_address,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "litemall_mall_address", $$v)
                  },
                  expression: "dataForm.litemall_mall_address"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "地理坐标" } },
            [
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "经度" },
                    model: {
                      value: _vm.dataForm.litemall_mall_longitude,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "litemall_mall_longitude", $$v)
                      },
                      expression: "dataForm.litemall_mall_longitude"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { "text-align": "center" }, attrs: { span: 2 } },
                [_vm._v("-")]
              ),
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "纬度" },
                    model: {
                      value: _vm.dataForm.litemall_mall_latitude,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "litemall_mall_latitude", $$v)
                      },
                      expression: "dataForm.litemall_mall_latitude"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话", prop: "litemall_mall_phone" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.dataForm.litemall_mall_phone,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "litemall_mall_phone", $$v)
                  },
                  expression: "dataForm.litemall_mall_phone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系QQ", prop: "litemall_mall_qq" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.dataForm.litemall_mall_qq,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForm, "litemall_mall_qq", $$v)
                  },
                  expression: "dataForm.litemall_mall_qq"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.update } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }