"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changePassword = changePassword;
exports.nNotice = nNotice;
exports.listNotice = listNotice;
exports.catNotice = catNotice;
exports.bcatNotice = bcatNotice;
exports.rmotice = rmotice;
exports.brmNotice = brmNotice;

var _request = _interopRequireDefault(require("@/utils/request"));

function changePassword(data) {
  return (0, _request.default)({
    url: '/profile/password',
    method: 'post',
    data: data
  });
}

function nNotice() {
  return (0, _request.default)({
    url: '/profile/nnotice',
    method: 'get'
  });
}

function listNotice(query) {
  return (0, _request.default)({
    url: '/profile/lsnotice',
    method: 'get',
    params: query
  });
}

function catNotice(data) {
  return (0, _request.default)({
    url: '/profile/catnotice',
    method: 'post',
    data: data
  });
}

function bcatNotice(data) {
  return (0, _request.default)({
    url: '/profile/bcatnotice',
    method: 'post',
    data: data
  });
}

function rmotice(data) {
  return (0, _request.default)({
    url: '/profile/rmnotice',
    method: 'post',
    data: data
  });
}

function brmNotice(data) {
  return (0, _request.default)({
    url: '/profile/brmnotice',
    method: 'post',
    data: data
  });
}