"use strict";

var _interopRequireWildcard3 = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _user = require("@/api/user");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var util = _interopRequireWildcard3(require("../../utils/index"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'User',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        uid: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      downloadLoading: false,
      genderDic: ['未知', '男', '女'],
      levelDic: ['普通用户', 'VIP用户', '高级VIP用户'],
      statusDic: ['可用', '禁用', '注销']
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _user.vipList)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this.list = response.data.d.list;

          _this.list.map(function (item) {
            item.createTimeCopy = item.createTime ? util.parseTime(item.createTime, '{y}-{m}-{d} {h}:{i}:{s}') : '';
            item.vipEndTimeCopy = item.vipEndTime ? util.parseTime(item.vipEndTime, '{y}-{m}-{d} {h}:{i}:{s}') : '';
          });

          if (response.data.d.totalPage) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleDownload: function handleDownload() {
      var _this2 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['用户名', '手机号码', '性别', '生日', '状态'];
        var filterVal = ['username', 'mobile', 'gender', 'birthday', 'status'];
        excel.export_json_to_excel2(tHeader, _this2.list, filterVal, '用户信息');
        _this2.downloadLoading = false;
      });
    }
  }
};
exports.default = _default;