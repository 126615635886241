var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-bottom": "20px" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "正在查询中。。。",
            "row-key": "id",
            border: ""
          }
        },
        [
          _c("el-table-column", { attrs: { label: "区域名称", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "区域类型", prop: "type" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("typeFilter")(scope.row.type)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { label: "区域编码", prop: "code" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }