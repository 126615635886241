import { render, staticRenderFns } from "./dayOptimization.vue?vue&type=template&id=40872693&"
import script from "./dayOptimization.vue?vue&type=script&lang=js&"
export * from "./dayOptimization.vue?vue&type=script&lang=js&"
import style0 from "./dayOptimization.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/zbyx-mall/admin/mall-admin/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40872693')) {
      api.createRecord('40872693', component.options)
    } else {
      api.reload('40872693', component.options)
    }
    module.hot.accept("./dayOptimization.vue?vue&type=template&id=40872693&", function () {
      api.rerender('40872693', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/promotion/dayOptimization.vue"
export default component.exports