"use strict";

var _interopRequireWildcard3 = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _interopRequireWildcard2 = _interopRequireDefault(require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

require("core-js/modules/es6.number.constructor");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var util = _interopRequireWildcard3(require("../../utils/index"));

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'User',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        uid: undefined,
        mobile: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      downloadLoading: false,
      dialogEmployeeVisible: false,
      // 员工对话框
      dialogRechargeVisible: false,
      // 充值对话框
      chargeConfirmDialogVisible: false,
      // 充值确认对话框
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      typeCode: 0,
      typeList: [{
        code: 0,
        name: '普通员工'
      }, {
        code: 1,
        name: '中标优选'
      }],
      changeUserDom: true,
      userCheckAll: false,
      userList: null,
      userTotal: 0,
      userListLoading: true,
      userListQuery: {
        page: 1,
        limit: 10,
        uid: undefined,
        mobile: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      },
      changeChargeDom: true,
      chargeCheckAll: false,
      chargeList: null,
      // 充值的员工数组
      chargeTotal: 0,
      chargeMoney: 200,
      // 充值金额
      chargeNumber: 0,
      // 充值人数
      chargeListLoading: true,
      chargeListQuery: {
        page: 1,
        limit: 10,
        uid: undefined,
        mobile: undefined,
        name: undefined,
        sort: 'add_time',
        order: 'desc'
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _user.listEmployee)(this.listQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this.list = response.data.d.list;

          _this.list.map(function (item) {
            item.createTimeCopy = item.createTime ? util.parseTime(item.createTime, '{y}-{m}-{d} {h}:{i}:{s}') : '';
            item.vipEndTimeCopy = item.vipEndTime ? util.parseTime(item.vipEndTime, '{y}-{m}-{d} {h}:{i}:{s}') : '';
          });

          if (response.data.d.totalPage && response.data.d.totalPage !== null) {
            _this.total = response.data.d.totalPage;
          }
        }

        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    // 获取用户列表
    getUserList: function getUserList() {
      var _this2 = this;

      this.userListLoading = true;
      this.userCheckAll = false;
      (0, _user.fetchList)(this.userListQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this2.userList = response.data.d.list;

          _this2.userList.map(function (item) {
            item.createTimeCopy = item.createTime ? util.parseTime(item.createTime, '{y}-{m}-{d} {h}:{i}:{s}') : '';
            item.vipEndTimeCopy = item.vipEndTime ? util.parseTime(item.vipEndTime, '{y}-{m}-{d} {h}:{i}:{s}') : '';
            item.checked = false;
          });

          if (response.data.d.totalPage) {
            _this2.userTotal = response.data.d.totalPage;
          }
        }

        _this2.userListLoading = false;
      }).catch(function () {
        _this2.userList = [];
        _this2.userTotal = 0;
        _this2.userListLoading = false;
      });
    },
    // 获取充值用户列表
    getCharegeList: function getCharegeList() {
      var _this3 = this;

      this.chargeListLoading = true;
      this.chargeCheckAll = false;
      (0, _user.fetchList)(this.chargeListQuery).then(function (response) {
        if (response && response.data && response.data.d) {
          _this3.chargeList = response.data.d.list;

          _this3.chargeList.map(function (item) {
            item.createTimeCopy = item.createTime ? util.parseTime(item.createTime, '{y}-{m}-{d} {h}:{i}:{s}') : '';
            item.vipEndTimeCopy = item.vipEndTime ? util.parseTime(item.vipEndTime, '{y}-{m}-{d} {h}:{i}:{s}') : '';
            item.checked = false;
          });

          if (response.data.d.totalPage) {
            _this3.chargeTotal = response.data.d.totalPage;
          }
        }

        _this3.chargeListLoading = false;
        console.log('当前查询到的数据', response);
      }).catch(function () {
        _this3.chargeList = [];
        _this3.chargeTotal = 0;
        _this3.chargeListLoading = false;
      });
    },
    // 主页面搜索
    handleFilter: function handleFilter(e) {
      var index = Number(e);

      if (index === 0) {
        this.listQuery.page = 1;
        this.getList();
      } else if (index === 1) {
        this.userListQuery.page = 1;
        this.getUserList();
      } else if (index === 2) {
        this.chargeListQuery.page = 1;
        this.getCharegeList();
      }
    },
    // 选中全部
    handleCheckedAll: function handleCheckedAll(e) {
      var _this4 = this;

      console.log('点击了勾选全部', e);
      var index = Number(e);

      if (index === 0) {
        console.log('当前选择了0');
      } else if (index === 1) {
        this.changeUserDom = false;

        if (this.userList && this.userList.length > 0) {
          for (var i = 0; i < this.userList.length; i++) {
            this.userList[i].checked = !this.userCheckAll;
          }
        }

        this.userCheckAll = !this.userCheckAll;
        setTimeout(function () {
          _this4.changeUserDom = true;
        }, 50);
      } else if (index === 2) {
        this.changeChargeDom = false;

        if (this.chargeList && this.chargeList.length > 0) {
          for (var _i = 0; _i < this.chargeList.length; _i++) {
            this.chargeList[_i].checked = !this.chargeCheckAll;
          }
        }

        this.chargeCheckAll = !this.chargeCheckAll;
        setTimeout(function () {
          _this4.changeChargeDom = true;
        }, 50);
      }
    },
    handleDownload: function handleDownload() {
      var _this5 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['用户名', '手机号码', '性别', '生日', '状态'];
        var filterVal = ['username', 'mobile', 'gender', 'birthday', 'status'];
        excel.export_json_to_excel2(tHeader, _this5.list, filterVal, '用户信息');
        _this5.downloadLoading = false;
      });
    },
    // 主页面点击充值
    handleRecharge: function handleRecharge() {
      this.dialogRechargeVisible = true;
      this.getCharegeList();
    },
    // 主页面点击添加员工
    handleCreate: function handleCreate() {
      this.dialogStatus = 'create';
      this.dialogEmployeeVisible = true;
      this.getUserList(); // this.$nextTick(() => {
      //   this.$refs['dataForm'].clearValidate()
      // })
    },
    // 主页面点击删除,其实是更改员工角色
    handleDelete: function handleDelete(e) {
      var _this6 = this;

      console.log('点击确认添加,当前选中的参数', e);
      var index = this.list.indexOf(e);
      var item = this.list[index];
      var arr = [];
      arr.push(item.uid);

      if (arr.length === 0) {
        return;
      }

      var params = {
        uidList: arr,
        employeeType: 0
      };
      (0, _user.markEmployee)(params).then(function (res) {
        _this6.$message('删除员工成功');

        _this6.getList();
      }).catch(function () {});
    },
    checkEmplpyeeChange: function checkEmplpyeeChange(e) {
      console.log('当前点击了checkbox', this.userList.indexOf(e));
      var index = this.userList.indexOf(e);
      this.userList[index].checked = !this.userList[index].checked;
      console.log('当前点击了checkbox', this.userList[index].checked);
    },
    checkChargeChange: function checkChargeChange(e) {
      var index = this.chargeList.indexOf(e);
      this.chargeList[index].checked = !this.chargeList[index].checked;
    },
    // 确认添加员工
    handleConfirmAdd: function handleConfirmAdd() {
      var _this7 = this;

      var arr = [];
      this.userList.forEach(function (item) {
        if (item.checked) {
          arr.push(item.uid);
        }
      });

      if (arr.length === 0) {
        return;
      }

      var params = {
        uidList: arr,
        employeeType: this.typeCode
      };
      console.log('点击确认添加,当前选中的参数', params);
      (0, _user.markEmployee)(params).then(function (res) {
        _this7.dialogEmployeeVisible = false;

        _this7.getList();
      }).catch(function () {});
    },
    // 确认充值
    handleConfirmCharge: function handleConfirmCharge() {
      this.chargeNumber = 0;
      var arr = [];
      this.chargeList.forEach(function (item) {
        if (item.checked) {
          arr.push(item.uid);
        }
      });

      if (arr.length === 0) {
        this.$message('请选择员工');
        return;
      } else {
        this.chargeNumber = arr.length;
        this.chargeConfirmDialogVisible = true;
      }
    },
    // 去进行充值
    getConfirmCharge: function getConfirmCharge() {
      var _this8 = this;

      var arr = [];
      this.chargeList.forEach(function (item) {
        if (item.checked) {
          arr.push(item.uid);
        }
      });

      if (arr.length === 0) {
        return;
      }

      var params = {
        uidList: arr,
        money: this.chargeMoney,
        reason: '管理平台充值'
      };
      console.log('点击确认添加,当前选中的参数', params);
      (0, _user.transferEmployee)(params).then(function (res) {
        _this8.$message('充值成功');

        _this8.chargeConfirmDialogVisible = false;

        _this8.getList();
      }).catch(function () {});
    }
  }
};
exports.default = _default;