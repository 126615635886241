"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listAftersale = listAftersale;
exports.receptAftersale = receptAftersale;
exports.batchReceptAftersale = batchReceptAftersale;
exports.rejectAftersale = rejectAftersale;
exports.batchRejectAftersale = batchRejectAftersale;
exports.refundAftersale = refundAftersale;

var _request = _interopRequireDefault(require("@/utils/request"));

function listAftersale(query) {
  return (0, _request.default)({
    url: '/aftersale/list',
    method: 'get',
    params: query
  });
}

function receptAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/recept',
    method: 'post',
    data: data
  });
}

function batchReceptAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/batch-recept',
    method: 'post',
    data: data
  });
}

function rejectAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/reject',
    method: 'post',
    data: data
  });
}

function batchRejectAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/batch-reject',
    method: 'post',
    data: data
  });
}

function refundAftersale(data) {
  return (0, _request.default)({
    url: '/aftersale/refund',
    method: 'post',
    data: data
  });
}