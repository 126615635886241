"use strict";

var _interopRequireDefault = require("/root/zbyx-mall/admin/mall-admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _stat = require("@/api/stat");

var _histogram = _interopRequireDefault(require("v-charts/lib/histogram"));

//
//
//
//
//
//
var _default = {
  components: {
    VeHistogram: _histogram.default
  },
  data: function data() {
    return {
      chartData: {},
      chartSettings: {},
      chartExtend: {}
    };
  },
  created: function created() {
    var _this = this;

    (0, _stat.statUser)().then(function (response) {
      _this.chartData = response.data.d;
      _this.chartSettings = {
        labelMap: {
          'users': '用户增长数'
        }
      };
      _this.chartExtend = {
        xAxis: {
          boundaryGap: true
        },
        series: {
          label: {
            show: true,
            position: 'top'
          }
        }
      };
    });
  }
};
exports.default = _default;